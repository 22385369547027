import { PlusCircleIcon } from "@heroicons/react/20/solid";
import { ArrowRightIcon, PencilSquareIcon } from "@heroicons/react/24/solid";

import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { useEffect } from "react";

export default function SubmitConfirmation({ content }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main>
      <div className="relative px-6 pt-14 lg:px-8">
        <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#f2b21c] to-[#d73822] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>

        <div className="px-6 py-24 sm:py-32 lg:px-8">
          <div className="mx-auto max-w-2xl text-center">
            {/* <p className="text-base font-semibold leading-7 text-[#CF4500]">Welcome to the</p> */}
            <motion.h2
              initial={{ opacity: 0, y: -100 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, ease: "easeOut" }}
              className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl"
            >
              🎉
            </motion.h2>

            <motion.h2
              initial={{ opacity: 0, y: -100 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, ease: "easeOut" }}
              className="mt-2 mcfont text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl"
            >
              Content Submitted
            </motion.h2>

            <p className="mt-6 mcfont font-bold text-lg leading-8 text-gray-800">
              Success! Your content has been submitted to the Priceless Content
              Pipeline.
            </p>

            <p className="mt-6 mcfont text-md leading-8 text-gray-800">
              Thank you for helping us create Priceless experiences for
              cardholders worldwide! 🌍 Please be sure to{" "}
              <Link
                className="underline font-semibold text-[#CF4500] hover:text-orange-800"
                to="/my-submissions"
              >
                review your submissions
              </Link>{" "}
              regularly to keep them up-to-date.
            </p>
          </div>

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1.5, ease: "easeInOut" }}
            className="mt-10 flex items-center justify-center gap-x-6"
          >
            <Link
              to="/submit"
              className="inline-flex mcfont items-center rounded-md bg-[#CF4500] px-5 py-4 text-lg font-semibold text-white shadow-sm hover:bg-orange-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-800"
            >
              Submit Pipeline Content{" "}
              <PlusCircleIcon
                className="ml-1 -mr-0.5 h-5 w-5"
                aria-hidden="true"
              />
            </Link>

            <Link
              to="/catalog"
              className="inline-flex mcfont items-center rounded-md bg-[#CF4500] px-5 py-4 text-lg font-semibold text-white shadow-sm hover:bg-orange-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-800"
            >
              Explore Content Catalog
              <ArrowRightIcon
                className="ml-1 -mr-0.5 h-5 w-5"
                aria-hidden="true"
              />
            </Link>
          </motion.div>

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1.5, ease: "easeInOut" }}
            className="mt-2 flex items-center justify-center mr-72 gap-x-6"
          >
            <Link
              to="/my-submissions"
              className="mcfont inline-flex items-center px-5 py-4 text-lg underline font-semibold text-[#CF4500] hover:text-orange-800"
            >
              Review My Submissions
              <PencilSquareIcon
                className="h-6 w-6 pl-1 text-[#CF4500]"
                aria-hidden="true"
              />
            </Link>
          </motion.div>
        </div>

        <div
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
      </div>
    </main>
  );
}
