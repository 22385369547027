import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
  ChevronDownIcon,
} from '@heroicons/react/20/solid';
import { Col, Row } from 'antd';
import React, { useState } from 'react';
import ReactPaginate from 'react-paginate';
import ProductCard from '../components/ProductCard';
import SearchProductCard from '../components/SearchProductCard';

export function PaginatedItems({
  data,
  itemsPerPage,
  handleShowDetails,
  isBetaSearchPage = false,
}) {
  const [itemOffset, setItemOffset] = useState(0);

  const getNextPageItems = start => {
    let collectedItems = [];
    let index = start;
    let contentCount = 0;

    while (contentCount < itemsPerPage && index < data.length) {
      const item = data[index];
      collectedItems.push(item);

      // Increment contentCount only if the item is not a group title
      if (!item.isGroupTitle) {
        contentCount++;
      }
      index++;
    }

    return collectedItems;
  };

  const currentItems = getNextPageItems(itemOffset);

  // Calculate the total number of content items (excluding group titles)
  const totalContentItems = data.filter(item => !item.isGroupTitle).length;

  const pageCount = Math.ceil(totalContentItems / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = event => {
    const newOffset = (event.selected * itemsPerPage) % data.length;
    // console.log(
    //   `User requested page number ${event.selected}, which is offset ${newOffset}`
    // );
    window.scrollTo(0, 0);
    setItemOffset(newOffset);
  };

  return (
    <>
      <Row gutter={[16, 16]} className="mb-6">
        {currentItems.map((item, index) =>
          item.isGroupTitle ? (
            <Col span={24} key={index}>
              <h2 className="mcfont uppercase font-semibold text-lg flex items-center drop-shadow-sm ">
                {item.groupTitle}
                <ChevronDownIcon
                  className="h-9 w-9 text-[#CF4500]"
                  aria-hidden="true"
                />
              </h2>
            </Col>
          ) : (
            <Col span={8} key={index}>
              {isBetaSearchPage ? (
                <SearchProductCard
                  data={item}
                  onClick={() => handleShowDetails(item)}
                />
              ) : (
                <ProductCard
                  data={item}
                  onClick={() => handleShowDetails(item)}
                />
              )}
            </Col>
          ),
        )}
      </Row>

      <ReactPaginate
        previousLabel={
          <div className="inline-flex items-center mcfont border-t-2 border-transparent pr-1 pt-4 text-sm font-semibold text-gray-500 hover:border-orange-500 hover:text-gray-700">
            <ArrowLongLeftIcon
              className="mr-3 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            Previous
          </div>
        }
        nextLabel={
          <div className="inline-flex items-center mcfont border-t-2 border-transparent pl-1 pt-4 text-sm font-semibold text-gray-500 hover:border-orange-500 hover:text-gray-700">
            Next
            <ArrowLongRightIcon
              className="ml-3 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </div>
        }
        breakLabel={'...'}
        breakClassName={
          'inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500'
        }
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={
          'flex items-center justify-between border-t border-gray-200 px-4 mb-8 sm:px-0'
        }
        pageClassName={
          'inline-flex items-center mcfont font-semibold border-t-2 border-transparent px-4 pt-4 text-sm text-gray-500 hover:border-gray-300 hover:text-gray-700'
        }
        previousClassName={'-mt-px flex w-0 flex-1'}
        nextClassName={'-mt-px flex w-0 flex-1 justify-end'}
        activeClassName={'border-orange-500 text-orange-600'}
      />
    </>
  );
}
