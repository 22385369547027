import { Select, TreeSelect } from 'antd';
import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { segments } from '../../data/segments';

import { FlagIcon } from '@heroicons/react/24/solid';
import { MarketsData } from '../../data/MarketsData';

const { SHOW_PARENT } = TreeSelect;

const visibleOutsideOriginOptions = [
  {
    id: 'visibleOutsideOrigin_Yes',
    value: 'yes',
    title: 'Yes, this content can be merchandized globally, in ALL MARKETS',
  },
  {
    id: 'visibleOutsideOrigin_WithRestrictions',
    value: 'WithRestrictions',
    title: 'Yes, this content can be merchandized in SOME MARKETS ',
  },
  {
    id: 'visibleOutsideOrigin_No',
    value: 'no',
    title: 'No, this is LOCAL content and can only be merchandized locally',
  },
];

const cobrandOptions = [
  { value: 'yes', title: 'Yes, by ALL partners' },
  { value: 'some', title: 'Yes, but only by SOME partners' },
  { value: 'no', title: 'No' },
];

const binaryOptions = [
  { value: 'yes', title: 'Yes' },
  { value: 'no', title: 'No' },
];

function ContentLimitationsBlock() {
  const {
    control,
    register,
    watch,
    formState: { errors },
  } = useFormContext();
  const visibleOutsideOriginCountry = watch('visibleOutsideOriginCountry');
  const cobrandSitePermissions = watch('cobrandSitePermissions');
  const apiPermissions = watch('apiPermissions');
  const areRestrictedSegments = watch('areRestrictedSegments');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
      <div>
        <div className="rounded-md bg-yellow-50 p-4">
          <div className="flex">
            <div>
              <h3 className="text-md font-semibold text-yellow-800">
                Content Limitations
              </h3>
              <div className="mb-3 mt-2 text-sm text-yellow-700">
                <p>
                  Please provide details on how this content should be
                  restricted by market, location, or other criteria.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-2xl space-y-10 md:col-span-2">
        <div className="col-span-full">
          <label
            htmlFor="visibleOutsideOriginCountry"
            className="block text-sm font-medium leading-6 text-gray-900">
            Can this content be visible outside the origin country?
          </label>
          {/* <p className="text-sm text-gray-500">Is any inventory reserved for specific purposes?</p> */}
          <fieldset className="mt-4">
            <div className="space-y-4">
              {visibleOutsideOriginOptions.map(option => (
                <div className="flex items-center" key={option.id}>
                  <input
                    {...register('visibleOutsideOriginCountry', {
                      required: true,
                    })}
                    id={option.id}
                    type="radio"
                    value={option.value}
                    className="relative size-4 appearance-none rounded-full border border-gray-300 bg-white before:absolute before:inset-1 before:rounded-full before:bg-white not-checked:before:hidden checked:border-orange-400 checked:bg-orange-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-400 disabled:border-gray-300 disabled:bg-gray-100 disabled:before:bg-gray-400 forced-colors:appearance-auto forced-colors:before:hidden"
                  />
                  <label
                    htmlFor={option.id}
                    className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                    {option.title}
                  </label>
                </div>
              ))}

              {errors.visibleOutsideOriginCountry && (
                <p className="mt-2 text-sm text-red-600" id="email-error">
                  Visible outside origin country value is required
                </p>
              )}
            </div>
          </fieldset>
        </div>

        {visibleOutsideOriginCountry === 'WithRestrictions' && (
          <div key="restrictedMarketsDiv" className="col-span-full">
            <label
              htmlFor="restrictedMarkets"
              className="block text-sm font-normal leading-6 text-gray-900">
              Please specify the external markets where this content can NOT be
              merchandized:
            </label>

            <Controller
              name="restrictedMarkets"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TreeSelect
                  {...field}
                  defaultValue={field.defaultValue}
                  treeData={MarketsData}
                  allowClear
                  treeCheckable={true}
                  showCheckedStrategy={SHOW_PARENT}
                  size="large"
                  style={{ width: '100%' }}
                  placeholder="Select markets..."
                  onChange={values => field.onChange(values)}
                />
              )}
            />

            {errors.restrictedMarkets && (
              <p className="mt-2 text-sm text-red-600">
                The list of allowed external markets is required
              </p>
            )}
          </div>
        )}

        {visibleOutsideOriginCountry === 'no' && (
          <div className="col-span-full">
            <label
              htmlFor="geographicRestrictionsReason"
              className="block text-sm font-normal leading-6 text-gray-900">
              Please specify the reasons for these restrictions.
            </label>
            <textarea
              {...register('geographicRestrictionsReason', { required: true })}
              type="text"
              className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline-2 focus:-outline-offset-2 focus:outline-orange-400 sm:text-sm/6"
            />

            {errors.geographicRestrictionsReason && (
              <p className="mt-2 text-sm text-red-600">
                Geographic restrictions details are required
              </p>
            )}
          </div>
        )}

        <div className="col-span-full">
          <label
            htmlFor="cobrandSitePermissions"
            className="block text-sm font-medium leading-6 text-gray-900">
            Can this content be leveraged by our partners' co-brand sites?
          </label>
          <p className="text-sm text-gray-500">
            The Priceless co-brand is a microsite built on the Priceless
            Platform that allows the B2B2C enrolled partner to showcase
            Priceless content from our catalog as well as bespoke and owned
            content.
          </p>
          <fieldset className="mt-4">
            <div className="space-y-4">
              {cobrandOptions.map(option => (
                <div className="flex items-center" key={option.id}>
                  <input
                    {...register('cobrandSitePermissions', { required: true })}
                    type="radio"
                    value={option.value}
                    id={'cobrandSitePermissions_' + option.value}
                    className="relative size-4 appearance-none rounded-full border border-gray-300 bg-white before:absolute before:inset-1 before:rounded-full before:bg-white not-checked:before:hidden checked:border-orange-400 checked:bg-orange-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-400 disabled:border-gray-300 disabled:bg-gray-100 disabled:before:bg-gray-400 forced-colors:appearance-auto forced-colors:before:hidden"
                  />
                  <label
                    htmlFor={'cobrandSitePermissions_' + option.value}
                    className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                    {option.title}
                  </label>
                </div>
              ))}
              {errors.cobrandSitePermissions && (
                <p className="mt-2 text-sm text-red-600">
                  Co-brand permissions value is required
                </p>
              )}
            </div>
          </fieldset>
        </div>

        {(cobrandSitePermissions === 'some' ||
          cobrandSitePermissions === 'no') && (
          <div className="col-span-full">
            <label
              htmlFor="cobrandSiteRestrictionDetails"
              className="block text-sm font-normal leading-6 text-gray-900">
              Please specify the cobranding restrictions and reasons for these
              restrictions.
            </label>
            <textarea
              {...register('cobrandSiteRestrictionDetails', { required: true })}
              type="text"
              className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline-2 focus:-outline-offset-2 focus:outline-orange-400 sm:text-sm/6"
            />

            {errors.cobrandSiteRestrictionDetails && (
              <p className="mt-2 text-sm text-red-600">
                Co-brand site restriction details are required
              </p>
            )}
          </div>
        )}

        <div className="col-span-full">
          <label
            htmlFor="publishDate"
            className="block text-sm font-medium leading-6 text-gray-900">
            Can this content be leveraged by our partners' API integrations?
          </label>
          <p className="text-sm text-gray-500">
            The Priceless API is an integrated solution allowing the B2B2C
            enrolled partner to embed Priceless content within their own digital
            environment (app or website).{' '}
          </p>

          <fieldset className="mt-4">
            <div className="space-y-4">
              {cobrandOptions.map(option => (
                <div className="flex items-center" key={option.id}>
                  <input
                    {...register('apiPermissions', { required: true })}
                    type="radio"
                    value={option.value}
                    id={'apiPermissions_' + option.value}
                    className="relative size-4 appearance-none rounded-full border border-gray-300 bg-white before:absolute before:inset-1 before:rounded-full before:bg-white not-checked:before:hidden checked:border-orange-400 checked:bg-orange-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-400 disabled:border-gray-300 disabled:bg-gray-100 disabled:before:bg-gray-400 forced-colors:appearance-auto forced-colors:before:hidden"
                  />
                  <label
                    htmlFor={'apiPermissions_' + option.value}
                    className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                    {option.title}
                  </label>
                </div>
              ))}
            </div>
          </fieldset>

          {errors.apiPermissions && (
            <p className="mt-2 text-sm text-red-600">
              API permissions value is required
            </p>
          )}
        </div>

        {(apiPermissions === 'some' || apiPermissions === 'no') && (
          <div className="col-span-full">
            <label
              htmlFor="apiRestrictionsDetails"
              className="block text-sm font-normal leading-6 text-gray-900">
              Please specify the partner API restrictions and reasons for these
              restrictions.
            </label>
            <textarea
              {...register('apiRestrictionsDetails', { required: true })}
              type="text"
              className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline-2 focus:-outline-offset-2 focus:outline-orange-400 sm:text-sm/6"
            />

            {errors.apiRestrictionsDetails && (
              <p className="mt-2 text-sm text-red-600">
                Partner API restrictions details are required
              </p>
            )}
          </div>
        )}

        <div className="col-span-full">
          <label
            htmlFor="restrictedSegments"
            className="block text-sm font-medium leading-6 text-gray-900">
            Is this content restricted in any industry segments?
          </label>
          <p className="text-sm text-gray-500 mb-2">
            Your content can be excluded from being merchandized in some
            specific industries or business categories.{' '}
          </p>

          <fieldset className="mt-4">
            <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
              {binaryOptions.map(option => (
                <div className="flex items-center" key={option.id}>
                  <input
                    {...register('areRestrictedSegments', { required: true })}
                    type="radio"
                    value={option.value}
                    id={'areRestrictedSegments_' + option.value}
                    className="relative size-4 appearance-none rounded-full border border-gray-300 bg-white before:absolute before:inset-1 before:rounded-full before:bg-white not-checked:before:hidden checked:border-orange-400 checked:bg-orange-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-400 disabled:border-gray-300 disabled:bg-gray-100 disabled:before:bg-gray-400 forced-colors:appearance-auto forced-colors:before:hidden"
                  />
                  <label
                    htmlFor={'areRestrictedSegments_' + option.value}
                    className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                    {option.title}
                  </label>
                </div>
              ))}
              {errors.areRestrictedSegments && (
                <p className="mt-2 text-sm text-red-600" id="email-error">
                  Restricted segments value is required
                </p>
              )}
            </div>
          </fieldset>
        </div>

        {areRestrictedSegments === 'yes' && (
          <div className="col-span-full">
            <p className="block text-sm font-medium leading-6 text-gray-900">
              Select all segments that should be restricted:
            </p>

            <Controller
              name="restrictedSegments"
              rules={{ required: true }}
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  defaultValue={field.defaultValue}
                  mode="multiple"
                  allowClear
                  style={{ width: '100%' }}
                  size="large"
                  placeholder="Select all segments that apply"
                  onChange={values => field.onChange(values)}
                  onBlur={() => field.onBlur()}
                  options={segments}
                />
              )}
            />

            {errors.restrictedSegments && (
              <p className="mt-2 text-sm text-red-600" id="email-error">
                Restricted segments value is required
              </p>
            )}
          </div>
        )}

        <div className="col-span-full">
          <label
            htmlFor="flagged"
            className="text-sm font-medium leading-6 text-gray-900 flex items-center">
            <FlagIcon
              className="h-5 w-5 mr-1 text-red-600"
              aria-hidden="true"
            />
            Would you like to "flag" this content for later review?
          </label>
          <p className="text-sm text-gray-500 mb-2">
            This field is visible only to you, and can be use to indicate that
            some content properties may change in the future.
          </p>
          <fieldset className="mt-4">
            <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
              {binaryOptions.map(option => (
                <div className="flex items-center" key={option.id}>
                  <input
                    {...register('flagged')}
                    type="radio"
                    value={option.value}
                    id={'flagged' + option.value}
                    className="relative size-4 appearance-none rounded-full border border-gray-300 bg-white before:absolute before:inset-1 before:rounded-full before:bg-white not-checked:before:hidden checked:border-orange-400 checked:bg-orange-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-400 disabled:border-gray-300 disabled:bg-gray-100 disabled:before:bg-gray-400 forced-colors:appearance-auto forced-colors:before:hidden"
                  />
                  <label
                    htmlFor={'flagged' + option.value}
                    className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                    {option.title}
                  </label>
                </div>
              ))}
            </div>
          </fieldset>
        </div>

        <div className="col-span-full">
          <label
            htmlFor="comments"
            className="block text-sm font-medium leading-6 text-gray-900">
            Any additional comments?
          </label>
          <div className="mt-2">
            <textarea
              {...register('comments')}
              rows={2}
              className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline-2 focus:-outline-offset-2 focus:outline-orange-400 sm:text-sm/6"
              defaultValue={''}
              placeholder=""
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContentLimitationsBlock;
