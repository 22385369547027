import { BrowserRouter, Routes, Route } from "react-router-dom";
import SubmitContentPage from "./pages/Content/SubmitContentPage";
import SubmissionConfirmation from "./SubmissionConfirmation";
import Home from "./pages/Home";
import NavBar from "./components/NavBar";
import SearchResults from "./pages/SearchResults";
import CatalogSearch from "./pages/CatalogSearch";
import { UserProvider, useUser } from "./UserProvider";
import { useEffect, useState } from "react";
import { supabase } from "./supabaseClient";
import "./tailwind.css";

import MySubmissions from "./pages/Content/MySubmissions";
import UpdateContentPage from "./pages/Content/UpdateContent";
import ProtectedRoute from "./ProtectedRoute";
import UnauthorizedPage from "./components/UnauthorizedPage";
import { LoadingPage } from "./components/LoadingPage";
import * as Sentry from "@sentry/react";
import Search from "./pages/Search";

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const AppContent = () => {
  const { user, setUser } = useUser();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function validateUser() {
      const params = new URLSearchParams(window.location.search);
      const fn = params.get("fn");
      const ln = params.get("ln");
      const role = params.get("role"); // this could be an array
      const email = params.get("ea");
      const hash = params.get("h1");
      const timestamp = params.get("ts");

      const { data, error } = await supabase.functions.invoke("validate-auth", {
        body: {
          fn: fn,
          ln: ln,
          role: role,
          email: email,
          hash: hash,
          timestamp: timestamp,
        },
      });

      if (error || data.error) {
        console.log("error is: ", error, data.error);
        // No need to clear out user object here, because we only call validate() if we don't have a session token.
      } else {
        console.log("User authenticated!");
        // Store in memory in app-wide context object
        setUser({
          role: role,
          firstName: fn,
          lastName: ln,
          email: email,
          sessionToken: data.access_token,
          id: data.user_id,
        });
      }
      setLoading(false);
    }

    // Only validate user if we don't already have a session token
    if (!user.sessionToken) {
      validateUser();
    }
  });

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <>
      {user.sessionToken && <NavBar />}
      <SentryRoutes>
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
        />
        <Route
          path="/submit"
          element={
            <ProtectedRoute>
              <SubmitContentPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/success"
          element={
            <ProtectedRoute>
              <SubmissionConfirmation />
            </ProtectedRoute>
          }
        />
        <Route
          path="/catalog"
          element={
            <ProtectedRoute>
              <CatalogSearch />
            </ProtectedRoute>
          }
        />
        <Route
          path="/results"
          element={
            <ProtectedRoute>
              <SearchResults />
            </ProtectedRoute>
          }
        />
        <Route
          path="/my-submissions"
          element={
            <ProtectedRoute>
              <MySubmissions />
            </ProtectedRoute>
          }
        />
        <Route
          path="/update"
          element={
            <ProtectedRoute>
              <UpdateContentPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/search"
          element={
            <ProtectedRoute>
              <Search />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<UnauthorizedPage />} />
      </SentryRoutes>
    </>
  );
};

export default function App() {
  return (
    <UserProvider>
      <BrowserRouter>
        <AppContent />
      </BrowserRouter>
    </UserProvider>
  );
}
