import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';

import { CheckIcon } from '@heroicons/react/24/solid';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../UserProvider';

import { Helmet } from 'react-helmet';
import ContentDetailsBlock from './ContentDetailsBlock.jsx';
import ContentLimitationsBlock from './ContentLimitationsBlock.jsx';
import ContentOverviewBlock from './ContentOverviewBlock.jsx';
import './SubmitContentPage.css';

import { supabase } from '../../supabaseClient';

const steps = [
  { name: 'Content Overview', href: '#', status: 'complete' },
  { name: 'Content Details', href: '#', status: 'current' },
  { name: 'Content Limitations', href: '#', status: 'upcoming' },
];

export const specialContentTypeStrings = {
  iconic: 'Iconic Destinations',
  ecoFriendly: 'Eco-friendly',
  epic: 'Epic',
  privilegedAccess: 'Content Privileged Access',
  specialTreat: 'Content Special Treat',
};

export const selectedSpecialProductTypes = form => {
  const specialProductTypes = [
    form.isIconic ? specialContentTypeStrings.iconic : null,
    form.ecoFriendly ? specialContentTypeStrings.ecoFriendly : null,
    form.isEpic ? specialContentTypeStrings.epic : null,
    form.privilegedAccess ? specialContentTypeStrings.privilegedAccess : null,
    form.specialTreat ? specialContentTypeStrings.specialTreat : null,
  ].filter(Boolean);
  return specialProductTypes;
};

export default function SubmitContentPage() {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const { user } = useUser();
  const [loading, setLoading] = useState(false);

  const methods = useForm({
    mode: 'onChange', // or you can use "onBlur"
  });

  const onSubmit = async form => {
    console.log(form);
    console.log(selectedSpecialProductTypes(form));

    if (page === 2) {
      setLoading(true);
      console.log('Ready to submit to DataStore!');

      const dataToInsert = {
        title: form.title,
        description: form.description,
        merchantName: form.merchant,
        publishDate: form.publishDate,
        salesCloseDate: form.closeDate,
        price: parseFloat(form.price),
        currencyCode: form.currency,
        peopleCount: parseInt(form.peopleCount),
        initialInventoryCount: parseInt(form.initialInventory),
        maxQuantityPerOrder: parseInt(form.maxQuantity),
        redemptionType: form.redemptionType,
        goLiveProbability: parseFloat(form.goLiveProbability),
        reservedInventoryDescription:
          form.isInventoryReserved === 'yes'
            ? form.reservedInventoryDetails
            : null,
        countryContentTakesPlace: form.country,
        city: form.city,
        contentType: form.contentType,
        passionCategory: form.category,
        sport: form.sports,
        subcategories: form.subcategories,
        isIconic: form.isIconic,
        ecoFriendly: form.ecoFriendly,
        isAlcoholAffiliated:
          form.alcoholAffiliated === undefined ? false : form.alcoholAffiliated,
        isAlwaysOn: form.alwaysOnDateDriven === 'Always On' ? true : false,
        isDateDriven:
          form.alwaysOnDateDriven === 'Single Date' ||
          form.alwaysOnDateDriven === 'Multiple Dates'
            ? true
            : false,
        alwaysOnDateDriven: form.alwaysOnDateDriven,
        linkedSponsorship:
          form.isSponsorshipLinked === 'yes' ? form.linkedSponsorship : null,
        visibleOutsideOriginCountry: form.visibleOutsideOriginCountry,
        reasonsForGeographicRestrictions: form.geographicRestrictionsReason,
        restrictedMarkets: form.restrictedMarkets,
        cobrandUsagePermissions: form.cobrandSitePermissions,
        cobrandUsageRestrictions:
          form.cobrandSitePermissions !== 'yes'
            ? form.cobrandSiteRestrictionDetails
            : null,
        apiUsagePermissions: form.apiPermissions,
        apiUsageRestrictions:
          form.apiPermissions !== 'yes' ? form.apiRestrictionsDetails : null,
        restrictedSegments: form.restrictedSegments,
        flagged: form.flagged === 'yes',
        comments: form.comments,
        special_product_types: selectedSpecialProductTypes(form),
      };

      let preLog = JSON.stringify({
        formData: form,
        dataToInsert: dataToInsert,
      });

      supabase.functions.invoke('pipeline-log', {
        body: JSON.stringify({ event: 'CREATE-BEFORE', body: preLog }),
        headers: { Authorization: `Bearer ${user.sessionToken}` },
      });

      // Can assume we have a session token.
      const { data, error } = await supabase.functions.invoke(
        'pipeline-create',
        {
          body: { content: dataToInsert },
          headers: { Authorization: `Bearer ${user.sessionToken}` },
        },
      );

      let log = JSON.stringify({
        formData: form,
        dataToInsert: dataToInsert,
        responseData: data,
        error: error,
      });

      supabase.functions.invoke('pipeline-log', {
        body: { event: 'CREATE-AFTER', body: log },
        headers: { Authorization: `Bearer ${user.sessionToken}` },
      });

      if (error) {
        console.error('Error inserting row:', error);
        alert(
          'An error occurred while submitting your content. Please refresh the page and try again.',
        );
      } else if (data.error) {
        console.error('Error inserting row:', data.error);
        alert(
          'An error occurred while submitting your content. Please refresh the page and try again.',
        );
      } else {
        console.log('Data inserted successfully:', data);
        navigate('/success');
      }
      setLoading(false);
    } else {
      setPage(page + 1);
    }
  };

  const conditionalFormPage = () => {
    switch (page) {
      case 0:
        return <ContentOverviewBlock />;
      case 1:
        return <ContentDetailsBlock />;
      case 2:
        return <ContentLimitationsBlock />;
      default:
        return <p>An error has occurred.</p>;
    }
  };

  return (
    <>
      <Helmet>
        <title>Submit Content | Priceless Content Catalog</title>
      </Helmet>
      <header className="bg-white shadow">
        <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold tracking-tight mcfont text-gray-900">
            Pipeline Content Submission Form
          </h1>

          <p className="mt-2 mcfont text-lg text-gray-500">
            Your pipeline content is key for the success of Priceless. We
            appreciate your time to help provide quality information.
          </p>
        </div>
      </header>

      <main>
        <div
          className={`mx-auto max-w-7xl py-6 sm:px-6 lg:px-8 relative ${
            loading ? 'opacity-50' : ''
          }`}>
          <div className="space-y-12">
            <nav aria-label="Progress">
              <ol
                role="list"
                className="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0">
                {steps.map((step, stepIdx) => (
                  <li key={step.name} className="relative md:flex md:flex-1">
                    {page > stepIdx ? ( // completed step
                      <div
                        href={step.href}
                        className="group flex w-full items-center">
                        <span className="flex items-center px-6 py-4 text-sm font-medium">
                          <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-orange-600 group-hover:bg-orange-700">
                            <CheckIcon
                              className="h-6 w-6 text-white"
                              aria-hidden="true"
                            />
                          </span>
                          <span className="ml-4 text-sm font-medium text-gray-900">
                            {step.name}
                          </span>
                        </span>
                      </div>
                    ) : page === stepIdx ? ( // current step
                      <div
                        href={step.href}
                        className="flex items-center px-6 py-4 text-sm font-medium"
                        aria-current="step">
                        <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-orange-600">
                          <span className="text-indigo-600">{step.id}</span>
                        </span>
                        <span className="ml-4 text-sm font-medium text-gray-900">
                          {step.name}
                        </span>
                      </div>
                    ) : (
                      <div href={step.href} className="group flex items-center">
                        <span className="flex items-center px-6 py-4 text-sm font-medium">
                          <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400">
                            <span className="text-gray-500 group-hover:text-gray-900">
                              {step.id}
                            </span>
                          </span>
                          <span className="ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900">
                            {step.name}
                          </span>
                        </span>
                      </div>
                    )}

                    {stepIdx !== steps.length - 1 ? (
                      <>
                        {/* Arrow separator for lg screens and up */}
                        <div
                          className="absolute right-0 top-0 hidden h-full w-5 md:block"
                          aria-hidden="true">
                          <svg
                            className="h-full w-full text-gray-300"
                            viewBox="0 0 22 80"
                            fill="none"
                            preserveAspectRatio="none">
                            <path
                              d="M0 -2L20 40L0 82"
                              vectorEffect="non-scaling-stroke"
                              stroke="currentcolor"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                      </>
                    ) : null}
                  </li>
                ))}
              </ol>
            </nav>
          </div>

          <FormProvider {...methods}>
            {loading && (
              <div className="absolute inset-0 flex items-center justify-center z-10">
                <svg
                  className="w-20 h-20 text-orange-600 animate-spin"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24">
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                <div className="absolute inset-0 bg-transparent pointer-events-auto"></div>
              </div>
            )}

            <form
              className="mt-7 relative z-0"
              disabled={loading}
              onSubmit={methods.handleSubmit(onSubmit)}>
              <AnimatePresence mode="wait">
                <motion.div
                  key={page}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.3 }}>
                  {conditionalFormPage()}
                </motion.div>
              </AnimatePresence>

              <div className="mt-6 flex items-center justify-end gap-x-6">
                {page !== 0 && (
                  <button
                    type="button"
                    disabled={loading}
                    className="font-semibold leading-6 text-gray-900"
                    onClick={() => setPage(page - 1)}>
                    Back
                  </button>
                )}

                <button
                  type="submit"
                  disabled={loading}
                  className="w-20 rounded-md bg-orange-600 px-3 py-2 font-semibold text-white shadow-sm hover:bg-orange-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                  {page !== 2 ? 'Next' : 'Submit'}
                </button>
              </div>
            </form>
          </FormProvider>
        </div>
      </main>
    </>
  );
}
