import { Dialog, Switch } from '@headlessui/react';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { create } from 'zustand';
import { useUser } from '../UserProvider';
import { supabase } from '../supabaseClient';
import { cleanString } from '../utils';
import { PaginatedItems } from './PaginatedItems';

const useSearchStore = create(set => ({
  localSearchText: '',
  query: '',
  results: [],
  loading: false,
  hasSearched: false,
  isModalOpen: false,
  selectedItem: null,
  enableAISearch: false,
  setSelectedItem: item => set({ selectedItem: item }),
  setQuery: query => set({ query }),
  setResults: results => set({ results }),
  setLoading: loading => set({ loading }),
  setHasSearched: hasSearched => set({ hasSearched }),
  setLocalSearchText: localSearchText => set({ localSearchText }),
  setIsModalOpen: isModalOpen => set({ isModalOpen }),
  setEnableAISearch: enableAISearch => set({ enableAISearch }),

  reset: () =>
    set({
      localSearchText: '',
      query: '',
      results: [],
      loading: false,
      hasSearched: false,
      isModalOpen: false,
      selectedItem: null,
      enableAISearch: false,
    }),
}));

const PinnedSearchBar = ({
  searchText,
  onSearchChange,
  onSearch,
  enableAISearch,
  setEnableAISearch,
}) => {
  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      onSearch();
    }
  };

  return (
    <div className="sticky top-0 z-50 bg-white shadow py-4 px-16 flex items-center gap-4 border-b border-gray-200">
      <input
        type="text"
        className="grow rounded-lg border border-gray-300 px-4 py-2 text-gray-800 focus:outline-none focus:ring focus:ring-orange-300"
        placeholder="e.g. south american adventure"
        value={searchText}
        onChange={e => onSearchChange(e.target.value)}
        onKeyDown={handleKeyDown}
      />
      <div className="flex items-center gap-2 m-1">
        <Switch
          checked={enableAISearch}
          onChange={setEnableAISearch}
          className={`${
            enableAISearch ? 'bg-orange-600' : 'bg-gray-200'
          } relative inline-flex h-6 w-11 items-center rounded-full transition-colors`}>
          <span
            className={`inline-block h-4 w-4 transform rounded-full bg-white shadow
                                    ring-0 transition-transform ease-in-out
                                    ${enableAISearch ? 'translate-x-6' : 'translate-x-1'}`}
          />
        </Switch>
        <span className="text-md mcfont font-semibold text-gray-700">
          Enable AI
        </span>
      </div>

      <button
        className="rounded-lg bg-orange-600 px-6 py-2 text-white font-semibold hover:bg-orange-800 focus:outline-none focus:ring focus:ring-orange-300"
        onClick={onSearch}>
        Search
      </button>
    </div>
  );
};

const heroVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -50 },
};

export default function SearchPage() {
  const {
    query,
    results,
    loading,
    hasSearched,
    setLoading,
    setResults,
    setQuery,
    setHasSearched,
    localSearchText,
    setLocalSearchText,
    isModalOpen,
    setIsModalOpen,
    selectedItem,
    setSelectedItem,
    enableAISearch,
    setEnableAISearch,
  } = useSearchStore();

  const { user } = useUser();

  useEffect(() => {
    // Reset store when component unmounts
    return () => {
      useSearchStore.getState().reset();
    };
  }, []);

  const handleSearch = async () => {
    if (!localSearchText) return;

    console.log('Searching for:', localSearchText);

    setQuery(localSearchText);
    setLoading(true);

    try {
      let responseData;

      if (enableAISearch) {
        const { data, error } = await supabase.functions.invoke(
          'hybrid-search',
          {
            body: { queryText: localSearchText },
            headers: { Authorization: `Bearer ${user.sessionToken}` },
          },
        );
        responseData = data.data || [];
      } else {
        const { data, error } = await supabase.functions.invoke('text-search', {
          body: { queryText: localSearchText },
          headers: { Authorization: `Bearer ${user.sessionToken}` },
        });
        responseData = data.data || [];
      }

      setResults(responseData);
      console.log('Search results:', responseData);
      setHasSearched(true);
    } catch (error) {
      console.error('Error fetching search results:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const handleShowDetails = item => {
    console.log('Show details for:', item);
    setSelectedItem(item);
    setIsModalOpen(true);
  };

  return (
    <>
      <Helmet>
        <title>Search | Priceless Content Catalog</title>
      </Helmet>

      <main
        className={`min-h-[calc(100vh-64px)] ${hasSearched ? 'bg-gray-50' : ''}`}>
        {/* Background Shape */}
        {!hasSearched && (
          <div
            className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
            aria-hidden="true">
            <div
              className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg]
               bg-gradient-to-tr from-[#f2b21c] to-[#d73822] opacity-50 sm:left-[calc(50%-30rem)]
               sm:w-[72.1875rem]"
              style={{
                clipPath:
                  'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
              }}
            />
          </div>
        )}

        {/* Pinned Search Bar */}
        {hasSearched && (
          <PinnedSearchBar
            searchText={localSearchText}
            onSearchChange={setLocalSearchText}
            onSearch={() => {
              handleSearch();
            }}
            enableAISearch={enableAISearch}
            setEnableAISearch={setEnableAISearch}
          />
        )}

        {/* Hero Search */}
        <AnimatePresence>
          {!hasSearched && (
            <motion.div
              key="heroSearch"
              variants={heroVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              className="flex justify-center items-center pt-48 px-6">
              <div className="max-w-3xl w-full bg-white rounded-2xl shadow-lg p-8">
                <div className="text-center">
                  <h1 className="mcfont text-3xl font-bold text-gray-900 sm:text-4xl">
                    Search the Catalog
                  </h1>
                  <p className="mcfont mt-2 text-lg leading-7 text-gray-600">
                    Query by product keyword (description, vendor, etc) or
                    natural language.
                  </p>
                </div>

                <div className="mt-8 flex items-center gap-4">
                  <input
                    type="text"
                    className="grow rounded-lg border border-gray-300 px-4 py-2 text-gray-800 shadow-sm
                               focus:outline-none focus:ring focus:ring-orange-300"
                    placeholder="e.g. south american adventure"
                    value={localSearchText}
                    onChange={e => setLocalSearchText(e.target.value)}
                    onKeyDown={handleKeyDown}
                    disabled={loading}
                  />

                  <div className="flex items-center gap-2 m-1">
                    <Switch
                      checked={enableAISearch}
                      onChange={setEnableAISearch}
                      className={`${
                        enableAISearch ? 'bg-orange-600' : 'bg-gray-200'
                      } relative inline-flex h-6 w-11 items-center rounded-full transition-colors`}>
                      <span
                        className={`inline-block h-4 w-4 transform rounded-full bg-white shadow
                                    ring-0 transition-transform ease-in-out
                                    ${enableAISearch ? 'translate-x-6' : 'translate-x-1'}`}
                      />
                    </Switch>
                    <span className="text-md mcfont font-semibold text-gray-700">
                      Enable AI
                    </span>
                  </div>

                  <button
                    className={`rounded-lg px-6 py-3 text-white font-semibold shadow-sm
                               focus:outline-none focus:ring focus:ring-orange-300
                               flex items-center gap-2
                               ${
                                 loading
                                   ? 'bg-orange-400 cursor-not-allowed'
                                   : 'bg-[#CF4500] hover:bg-orange-800'
                               }`}
                    onClick={handleSearch}
                    disabled={loading}>
                    {loading ? <>Searching...</> : 'Search'}
                  </button>
                </div>

                {!loading && results.length === 0 && query && (
                  <p className="mt-4 text-center text-gray-500">
                    No results found for "{query}".
                  </p>
                )}
              </div>
            </motion.div>
          )}
        </AnimatePresence>

        {/* Results Section */}
        <div className="mt-6 mb-16 pb-8 mx-auto max-w-[1400px] px-4">
          {!loading && results.length > 0 && (
            <>
              <p className="text-gray-600 mcfont mb-6">
                Found {results.length} results for "{query}"
              </p>
              <PaginatedItems
                data={results}
                itemsPerPage={18}
                handleShowDetails={handleShowDetails}
                isBetaSearchPage={true}
              />
            </>
          )}

          {loading && hasSearched && (
            <p className="text-center text-gray-500 my-8">Loading...</p>
          )}

          {!loading && results.length === 0 && hasSearched && (
            <p className="text-center text-gray-500 my-8">
              No results found for "{query}".
            </p>
          )}
        </div>
      </main>

      <Dialog
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        className="relative z-50">
        {/* Backdrop */}
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

        {/* Full-screen container */}
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel className="mx-auto max-w-2xl min-w-[32rem] rounded-lg bg-white p-6">
            <Dialog.Title className="text-lg font-bold mcfont">
              {selectedItem && cleanString(selectedItem.displayName)}
            </Dialog.Title>

            <div className="mt-4">
              <span className="font-semibold mcfont text-gray-700">
                Description:{' '}
              </span>
              {selectedItem && (
                <p className="inline mcfont">
                  {cleanString(selectedItem.shortDescription)}
                </p>
              )}
            </div>

            <div className="mt-6 flex justify-end">
              <button
                className="rounded-lg bg-orange-600 px-4 py-2 text-white font-semibold hover:bg-orange-800 focus:outline-none focus:ring focus:ring-orange-300"
                onClick={() => setIsModalOpen(false)}>
                Close
              </button>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  );
}
