import { CheckIcon } from '@heroicons/react/20/solid';
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import { DatePicker, Select, Tooltip, TreeSelect } from 'antd';
import dayjs from 'dayjs';
import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { categories } from '../data/categories';
import { contentTypes } from '../data/contentTypes';
import { sports } from '../data/sports';
import { classNames } from '../utils';

import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { useUser } from '../UserProvider';
import { specialContentTypeStrings } from './Content/SubmitContentPage';

const { SHOW_PARENT } = TreeSelect;

const steps = [
  {
    name: 'Availability',
  },
  {
    name: 'Regions / Markets',
  },
  {
    name: 'Implementation Type',
  },
  {
    name: 'Content Type',
  },
  {
    name: 'Passion Category',
  },
  {
    name: 'Languages',
  },
];

const PTROptions = [
  {
    id: 'PTR_ALLOWED',
    title:
      'Yes, show me content that can be merchandized by B2B2C integrated partners (i.e. content with PTRs)',
  },
  {
    id: 'PTR_NO_PREFERENCE',
    title: 'No',
  },
];

const ImplementationOptions = [
  {
    id: 'IX_B2B',
    title: (
      <>
        Content available for <strong className="font-bold">B2B only</strong>
      </>
    ),
  },
  {
    id: 'IX_B2C',
    title: (
      <>
        Content available for <strong className="font-bold">B2C only</strong>
      </>
    ),
  },
  {
    id: 'IX_BOTH',
    title: 'Content available for either B2B or B2C implementations',
  },
];

const specialProductTypes = [
  {
    value: specialContentTypeStrings.ecoFriendly,
    label: 'Eco-friendly Experiences',
    description:
      'From zero-waste meals to eco-friendly outdoor adventures, these experiences celebrate our planet and inspire everyday environmentally friendly practices.',
  },
  {
    value: specialContentTypeStrings.epic,
    label: 'Epic Experiences',
    description:
      'Rare and truly once-in-a-lifetime sponsorship property (event) or brand ambassador-led experiences.',
  },
  {
    value: specialContentTypeStrings.iconic,
    label: 'Iconic Experiences',
    description:
      'Well-established experiences that happen within an iconic, high tourism value destination and involve a top tourist attraction. Best of the best. E.g. Eiffel Tower - Paris.',
  },
  {
    value: specialContentTypeStrings.privilegedAccess,
    label: 'Privileged Access',
    description:
      'Access (including pre-sale and post-sale access) to hard-to-get premier locations, event/shows, restaurants, etc.',
  },
  {
    value: specialContentTypeStrings.specialTreat,
    label: 'Special Treat',
    description:
      'An always-on, value-add tied to experience provider that makes the experience more enjoyable and pleasant (e.g. complimentary drink, BOGO).',
  },
];

const transition = { type: 'spring', stiffness: 300, damping: 30 };

export default function CatalogSearch() {
  const { countriesByRegion, languages } = useUser();
  const [isSportsChecked, setIsSportsChecked] = useState(false);

  const {
    control,
    handleSubmit,
    register,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      languages: languages.some(lang => lang.value === 'English (US)')
        ? ['English (US)']
        : [],
    },
  });
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const countries = watch('countries', []);
  const implementationTypeSelection = watch('ix');
  const ptrSelection = watch('ptr');
  const contentType = watch('contentType', []);

  const handleSportsChange = event => {
    setIsSportsChecked(event.target.checked);
  };

  const onSubmit = async data => {
    if (page < 5) {
      console.log(data);

      setPage(page + 1);
      return;
    }

    let statusesToSearch = [];
    if (data.contentStatus) {
      switch (data.contentStatus) {
        case 'Live':
          statusesToSearch.push('Live');
          break;
        case 'Coming Soon':
          statusesToSearch.push('Coming Soon');
          break;
        case 'Both Live and Coming Soon':
          statusesToSearch.push('Live', 'Coming Soon');
          break;
        default:
          break;
      }
      data.contentStatus = statusesToSearch;
    }

    let integrationTypesToSearch = [];
    if (data.ix) {
      switch (data.ix) {
        case 'IX_B2B':
          integrationTypesToSearch.push('IX_B2B');
          break;
        case 'IX_B2C':
          integrationTypesToSearch.push('IX_B2C');
          break;
        case 'IX_BOTH':
          integrationTypesToSearch.push('IX_B2B', 'IX_B2C');
          break;
        default:
          break;
      }
      data.ix = integrationTypesToSearch;
    }

    if (data.ptr !== 'PTR_ALLOWED') {
      delete data['ptr2'];
    }

    // console.log("AFTER data.ptr2", data.ptr2);

    if (!isSportsChecked) {
      delete data['sports'];
    }

    if (data.availabilityDate) {
      data.availabilityDate = dayjs(data.availabilityDate).format('YYYY-MM-DD');
    }

    // Redirecting to the results page with query parameters
    navigate('/results', { state: data });
  };

  const selectAllCountries = () => {
    setValue(
      'countries',
      countriesByRegion.map(item => item.value),
      { shouldValidate: true },
    );
  };

  const selectAllCategories = () => {
    setValue(
      'category',
      categories.map(item => item.value),
      { shouldValidate: true },
    );
    setIsSportsChecked(true);
    selectAllSports();
  };

  const selectAllSports = () => {
    setValue(
      'sports',
      sports.map(item => item.value),
      { shouldValidate: true },
    );
  };

  const selectAllContentTypes = () => {
    setValue(
      'contentType',
      contentTypes.map(item => item.value),
      { shouldValidate: true },
    );
  };

  const selectAllLanguages = () => {
    setValue(
      'languages',
      languages.map(item => item.value),
      { shouldValidate: true },
    );
  };

  console.log('Ptr selection is: ', ptrSelection);

  return (
    <>
      <Helmet>
        <body className="bg-gray-100" />
        <title>Search | Priceless Content Catalog</title>
      </Helmet>

      <header className="bg-white shadow">
        <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold tracking-tight mcfont text-gray-900">
            Search the Catalog
          </h1>

          <p className="mt-2 mcfont text-lg text-gray-500">
            Please answer the following brief questions so we can effectively
            tailor your search results.
          </p>
        </div>
      </header>

      <main className="flex items-start justify-center my-8">
        <motion.div
          initial={{ opacity: 0, y: -100 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, ease: 'easeOut' }}
          className="bg-white w-full rounded-xl shadow-md max-w-6x max-w-6xl py-6 sm:px-6 lg:px-8 relative">
          <div className="flex w-full my-4">
            <div className="w-4/12 mr-5 ">
              <nav aria-label="Progress">
                <ol className="overflow-hidden">
                  {steps.map((step, stepIdx) => (
                    <li
                      key={step.name}
                      className={classNames(
                        stepIdx !== steps.length - 1 ? 'pb-10' : '',
                        'relative',
                      )}>
                      {page > stepIdx ? ( // completed step
                        <>
                          {stepIdx !== steps.length - 1 ? (
                            <div
                              className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-orange-600"
                              aria-hidden="true"
                            />
                          ) : null}
                          <div
                            // onClick={() => setPage(stepIdx)}
                            className="group relative flex items-start">
                            <span className="flex h-9 items-center">
                              <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-orange-600 group-hover:bg-orange-800">
                                <CheckIcon
                                  className="h-5 w-5 text-white"
                                  aria-hidden="true"
                                />
                              </span>
                            </span>
                            <span className="ml-4 flex min-w-0 flex-col">
                              <span className="text-md mt-1 mcfont font-bold">
                                {step.name}
                              </span>
                            </span>
                          </div>
                        </>
                      ) : page === stepIdx ? ( // current step
                        <>
                          {stepIdx !== steps.length - 1 ? (
                            <div
                              className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300"
                              aria-hidden="true"
                            />
                          ) : null}
                          <div
                            // onClick={() => setPage(stepIdx)}
                            className="group relative flex items-start"
                            aria-current="step">
                            <span
                              className="flex h-9 items-center"
                              aria-hidden="true">
                              <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-orange-600 bg-white">
                                <span className="h-2.5 w-2.5 rounded-full bg-orange-600" />
                              </span>
                            </span>
                            <span className="ml-4 flex min-w-0 flex-col">
                              <span className="text-md mt-1 mcfont font-bold text-orange-700">
                                {step.name}
                              </span>
                            </span>
                          </div>
                        </>
                      ) : (
                        <>
                          {stepIdx !== steps.length - 1 ? (
                            <div
                              className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300"
                              aria-hidden="true"
                            />
                          ) : null}
                          <div className="group relative flex items-start">
                            <span
                              className="flex h-9 items-center"
                              aria-hidden="true">
                              <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white group-hover:border-gray-400">
                                <span className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300" />
                              </span>
                            </span>
                            <span className="ml-4 flex min-w-0 flex-col">
                              <span className="text-md mt-1 mcfont font-bold text-gray-500">
                                {step.name}
                              </span>
                            </span>
                          </div>
                        </>
                      )}
                    </li>
                  ))}
                </ol>
              </nav>
            </div>

            <div className="border-l border-gray-200 w-8/12 pl-10">
              <form
                className="mx-auto space-y-4 max-w-3xl"
                onSubmit={handleSubmit(onSubmit)}>
                <AnimatePresence mode="wait">
                  {page === 0 && (
                    <motion.div
                      key="step0"
                      className="col-span-full"
                      initial={{ opacity: 0, x: '100vw' }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={transition}>
                      <label className="block text-xl mcfont font-semibold mb-6 text-gray-900">
                        Are you interested in live content (already published on
                        priceless.com), coming soon content, or both?
                      </label>

                      <fieldset className="mt-5 flex flex-col ">
                        <div className="space-y-4 mb-2 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
                          <div key={'Live'} className="flex items-center">
                            <input
                              {...register('contentStatus', { required: true })}
                              type="radio"
                              id={'Live'}
                              value={'Live'}
                              className="relative size-4 appearance-none rounded-full border border-gray-300 bg-white before:absolute before:inset-1 before:rounded-full before:bg-white not-checked:before:hidden checked:border-orange-400 checked:bg-orange-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:border-gray-300 disabled:bg-gray-100 disabled:before:bg-gray-400 forced-colors:appearance-auto forced-colors:before:hidden"
                            />

                            <label
                              htmlFor={'Live'}
                              className={classNames(
                                'text-gray-900',
                                'ml-3 block text-lg mcfont font-medium leading-6 ',
                              )}>
                              Live content
                            </label>
                          </div>
                          <div
                            key={'Coming Soon'}
                            className="flex items-center">
                            <input
                              {...register('contentStatus', { required: true })}
                              type="radio"
                              id={'Coming Soon'}
                              value={'Coming Soon'}
                              className="relative size-4 appearance-none rounded-full border border-gray-300 bg-white before:absolute before:inset-1 before:rounded-full before:bg-white not-checked:before:hidden checked:border-orange-400 checked:bg-orange-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:border-gray-300 disabled:bg-gray-100 disabled:before:bg-gray-400 forced-colors:appearance-auto forced-colors:before:hidden"
                            />
                            <label
                              htmlFor={'Coming Soon'}
                              className={classNames(
                                'text-gray-900',
                                'ml-3 block text-lg mcfont font-medium leading-6 ',
                              )}>
                              Coming soon content
                            </label>
                          </div>
                          <div
                            key={'Both Live and Coming Soon'}
                            className="flex items-center">
                            <input
                              {...register('contentStatus', { required: true })}
                              type="radio"
                              id={'Both Live and Coming Soon'}
                              value={'Both Live and Coming Soon'}
                              className="relative size-4 appearance-none rounded-full border border-gray-300 bg-white before:absolute before:inset-1 before:rounded-full before:bg-white not-checked:before:hidden checked:border-orange-400 checked:bg-orange-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:border-gray-300 disabled:bg-gray-100 disabled:before:bg-gray-400 forced-colors:appearance-auto forced-colors:before:hidden"
                            />
                            <label
                              htmlFor={'Both Live and Coming Soon'}
                              className={classNames(
                                'text-gray-900',
                                'ml-3 block text-lg mcfont font-medium leading-6 ',
                              )}>
                              Both
                            </label>
                          </div>
                        </div>
                      </fieldset>
                      {errors.contentStatus && (
                        <p
                          className="mt-2 mcfont text-md text-red-600"
                          id="email-error">
                          Required
                        </p>
                      )}

                      <label className="block text-xl mcfont font-semibold mt-12 text-gray-900">
                        Are you looking for content that will be available on
                        priceless.com as from a specific date?
                      </label>

                      <p className="text-md mcfont my-2 text-gray-500">
                        If so, please select a date. Otherwise, tap "Next".
                      </p>

                      <Controller
                        name="availabilityDate"
                        control={control}
                        // className="mt-4"
                        render={({ field }) => (
                          <DatePicker
                            {...field}
                            size="large"
                            className="w-48 mt-4"
                            value={field.value}
                            onChange={values => field.onChange(values)}
                          />
                        )}
                      />
                    </motion.div>
                  )}

                  {page === 1 && (
                    <motion.div
                      key="step1"
                      initial={{ opacity: 0, x: '100vw' }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={transition}>
                      <label className="block text-xl mcfont font-semibold mb-2 text-gray-900">
                        In which regions / markets would you like to search for
                        content?
                      </label>

                      <p className="text-md mcfont text-gray-500">
                        Please select all that apply.
                      </p>

                      <button
                        type="button"
                        onClick={selectAllCountries}
                        className="mcfont pt-4 text-md underline font-semibold text-[#CF4500] hover:text-orange-800">
                        Select all
                      </button>

                      <div className="mt-4 mb-4">
                        <Controller
                          name="countries"
                          control={control}
                          rules={{
                            required: true,
                          }}
                          render={({ field }) => (
                            <TreeSelect
                              {...field}
                              defaultValue={field.defaultValue}
                              treeData={countriesByRegion}
                              allowClear
                              treeCheckable={true}
                              showCheckedStrategy={SHOW_PARENT}
                              size="large"
                              style={{ width: '100%' }}
                              placeholder="Select markets..."
                              onChange={values => field.onChange(values)}
                            />
                          )}
                        />
                        {errors.countries && (
                          <p
                            className="mt-2 mcfont text-md text-red-600"
                            id="email-error">
                            Required
                          </p>
                        )}
                      </div>

                      {Object.keys(countries).length !== 0 && (
                        <div>
                          <label className="block mt-12 text-xl mcfont font-semibold mb-2 text-gray-900">
                            Are you looking for content that can be merchandized
                            only in the origin country, content that can be
                            merchandized globally, or both?
                          </label>

                          <p className="text-md mcfont text-gray-500">
                            Please select all that apply.
                          </p>
                          <fieldset className="mt-4 flex flex-col">
                            <div className="space-y-4 mb-2 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
                              <div
                                key={'Local Only'}
                                className="flex items-center">
                                <div className="group grid size-4 grid-cols-1">
                                  <input
                                    {...register('globalAvailability', {
                                      required: true,
                                    })}
                                    type="checkbox"
                                    id={'Local Only'}
                                    value={'Local'}
                                    className="col-start-1 row-start-1 appearance-none rounded-sm border border-gray-300 bg-white checked:border-orange-400 checked:bg-orange-400 indeterminate:border-orange-400 indeterminate:bg-orange-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-400 disabled:border-gray-300 disabled:bg-gray-100 disabled:checked:bg-gray-100 forced-colors:appearance-auto"
                                  />

                                  <svg
                                    fill="none"
                                    viewBox="0 0 14 14"
                                    className="pointer-events-none col-start-1 row-start-1 size-3.5 self-center justify-self-center stroke-white group-has-disabled:stroke-gray-950/25">
                                    <path
                                      d="M3 8L6 11L11 3.5"
                                      strokeWidth={2}
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="opacity-0 group-has-checked:opacity-100"
                                    />
                                    <path
                                      d="M3 7H11"
                                      strokeWidth={2}
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="opacity-0 group-has-indeterminate:opacity-100"
                                    />
                                  </svg>
                                </div>

                                <label
                                  htmlFor={'Local Only'}
                                  className={classNames(
                                    'text-gray-800',
                                    'ml-3 block text-lg mcfont font-medium leading-6 ',
                                  )}>
                                  Local Market Content
                                </label>
                              </div>

                              <div
                                key={'Available Globally'}
                                className="flex items-center">
                                <div className="group grid size-4 grid-cols-1">
                                  <input
                                    {...register('globalAvailability', {
                                      required: true,
                                    })}
                                    type="checkbox"
                                    id={'Available Globally'}
                                    value={'Available Globally'}
                                    className="col-start-1 row-start-1 appearance-none rounded-sm border border-gray-300 bg-white checked:border-orange-400 checked:bg-orange-400 indeterminate:border-orange-400 indeterminate:bg-orange-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-400 disabled:border-gray-300 disabled:bg-gray-100 disabled:checked:bg-gray-100 forced-colors:appearance-auto"
                                  />

                                  <svg
                                    fill="none"
                                    viewBox="0 0 14 14"
                                    className="pointer-events-none col-start-1 row-start-1 size-3.5 self-center justify-self-center stroke-white group-has-disabled:stroke-gray-950/25">
                                    <path
                                      d="M3 8L6 11L11 3.5"
                                      strokeWidth={2}
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="opacity-0 group-has-checked:opacity-100"
                                    />
                                    <path
                                      d="M3 7H11"
                                      strokeWidth={2}
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="opacity-0 group-has-indeterminate:opacity-100"
                                    />
                                  </svg>
                                </div>
                                <label
                                  htmlFor={'Available Globally'}
                                  className={classNames(
                                    'text-gray-800',
                                    'ml-3 block text-lg mcfont font-medium leading-6 ',
                                  )}>
                                  Globally Available Content
                                </label>
                              </div>
                            </div>
                          </fieldset>

                          {errors.globalAvailability && (
                            <p
                              className="mt-2 mcfont text-md text-red-600"
                              id="email-error">
                              Required
                            </p>
                          )}
                        </div>
                      )}
                    </motion.div>
                  )}

                  {page === 2 && (
                    <div key="step2_container" className="col-span-full">
                      <div key={'step2_ix'}>
                        <label className="block text-xl mcfont mb-2 font-semibold text-gray-900">
                          Are you looking for B2B content, B2C content, or both?
                        </label>
                        {/* <p className="text-md mcfont mt-1 text-gray-500">
                        B2B-only content is sourced by the priceless team for B2B implementations and is not available for B2C.
                      </p> */}

                        <fieldset className="mt-5">
                          <legend className="sr-only">
                            Implementation Types
                          </legend>
                          <div className="space-y-5">
                            {ImplementationOptions.map(option => (
                              <div
                                key={option.id}
                                className="flex items-center">
                                <input
                                  {...register('ix', {
                                    required: true,
                                  })}
                                  id={option.id}
                                  value={option.id}
                                  type="radio"
                                  className="shrink-0 relative size-4 appearance-none rounded-full border border-gray-300 bg-white before:absolute before:inset-1 before:rounded-full before:bg-white not-checked:before:hidden checked:border-orange-400 checked:bg-orange-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:border-gray-300 disabled:bg-gray-100 disabled:before:bg-gray-400 forced-colors:appearance-auto forced-colors:before:hidden"
                                />
                                <label
                                  htmlFor={option.id}
                                  className="ml-3 block text-lg font-medium mcfont leading-6 text-gray-900">
                                  {option.title}
                                </label>
                              </div>
                            ))}
                          </div>
                        </fieldset>
                        {errors.ix && (
                          <p
                            className="mt-4 mcfont text-sm text-red-600"
                            id="implementation-type-error">
                            Required
                          </p>
                        )}
                      </div>

                      {(implementationTypeSelection === 'IX_B2B' ||
                        implementationTypeSelection === 'IX_BOTH') && (
                        <div key={'step2_ptr'} className="col-span-full mt-10">
                          <label className="block text-xl mcfont mb-2 font-semibold text-gray-900">
                            Do you need content that can be merchandized by
                            Mastercard B2B2C customers?
                          </label>
                          <p className="text-md mcfont mt-1 text-gray-500">
                            This is also known as content with 'pass-through
                            rights' (PTRs) for our customers.
                          </p>

                          <fieldset className="mt-5">
                            <legend className="sr-only">
                              Pass-through Rights
                            </legend>
                            <div className="space-y-5">
                              {PTROptions.map(option => (
                                <div
                                  key={option.id}
                                  className="flex items-center">
                                  <input
                                    {...register('ptr', {
                                      required: true,
                                    })}
                                    id={option.id}
                                    value={option.id}
                                    type="radio"
                                    className="shrink-0 relative size-4 appearance-none rounded-full border border-gray-300 bg-white before:absolute before:inset-1 before:rounded-full before:bg-white not-checked:before:hidden checked:border-orange-400 checked:bg-orange-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:border-gray-300 disabled:bg-gray-100 disabled:before:bg-gray-400 forced-colors:appearance-auto forced-colors:before:hidden"
                                  />
                                  <label
                                    htmlFor={option.id}
                                    className="ml-3 block text-lg font-medium mcfont leading-6 text-gray-900">
                                    {option.title}
                                  </label>
                                </div>
                              ))}
                            </div>
                          </fieldset>
                          {errors.ptr && (
                            <p
                              className="mt-4 mcfont text-sm text-red-600"
                              id="email-error">
                              Required
                            </p>
                          )}

                          {ptrSelection === 'PTR_ALLOWED' && (
                            <div
                              key={'step2_ptr_type'}
                              className="col-span-full mt-8">
                              <label className="block text-xl mcfont font-semibold mt-10 mb-2 text-gray-900">
                                Should this content be available through partner
                                co-branded sites, partner APIs, or both?
                              </label>
                              <p className="text-md mcfont text-gray-500">
                                Select all that apply. Or, leave blank to search
                                for either type.
                              </p>

                              <fieldset className="mt-4 flex flex-col">
                                <div className="space-y-4 mb-2 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
                                  <div
                                    key={'PTR-Cobrand'}
                                    className="flex items-center">
                                    <div className="group grid size-4 grid-cols-1">
                                      <input
                                        {...register('ptr2', {
                                          required: false,
                                        })}
                                        type="checkbox"
                                        id={'PTR-Cobrand'}
                                        value={'PTR-Cobrand'}
                                        className="col-start-1 row-start-1 appearance-none rounded-sm border border-gray-300 bg-white checked:border-orange-400 checked:bg-orange-400 indeterminate:border-orange-400 indeterminate:bg-orange-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-400 disabled:border-gray-300 disabled:bg-gray-100 disabled:checked:bg-gray-100 forced-colors:appearance-auto"
                                      />

                                      <svg
                                        fill="none"
                                        viewBox="0 0 14 14"
                                        className="pointer-events-none col-start-1 row-start-1 size-3.5 self-center justify-self-center stroke-white group-has-disabled:stroke-gray-950/25">
                                        <path
                                          d="M3 8L6 11L11 3.5"
                                          strokeWidth={2}
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          className="opacity-0 group-has-checked:opacity-100"
                                        />
                                        <path
                                          d="M3 7H11"
                                          strokeWidth={2}
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          className="opacity-0 group-has-indeterminate:opacity-100"
                                        />
                                      </svg>
                                    </div>

                                    <label
                                      htmlFor={'PTR-Cobrand'}
                                      className={classNames(
                                        'text-gray-800',
                                        'ml-3 block text-lg mcfont font-medium leading-6 ',
                                      )}>
                                      Co-branded sites
                                    </label>
                                  </div>

                                  <div
                                    key={'PTR-API'}
                                    className="flex items-center">
                                    <div className="group grid size-4 grid-cols-1">
                                      <input
                                        {...register('ptr2', {
                                          required: false,
                                        })}
                                        type="checkbox"
                                        id={'PTR-API'}
                                        value={'PTR-API'}
                                        className="col-start-1 row-start-1 appearance-none rounded-sm border border-gray-300 bg-white checked:border-orange-400 checked:bg-orange-400 indeterminate:border-orange-400 indeterminate:bg-orange-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-400 disabled:border-gray-300 disabled:bg-gray-100 disabled:checked:bg-gray-100 forced-colors:appearance-auto"
                                      />

                                      <svg
                                        fill="none"
                                        viewBox="0 0 14 14"
                                        className="pointer-events-none col-start-1 row-start-1 size-3.5 self-center justify-self-center stroke-white group-has-disabled:stroke-gray-950/25">
                                        <path
                                          d="M3 8L6 11L11 3.5"
                                          strokeWidth={2}
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          className="opacity-0 group-has-checked:opacity-100"
                                        />
                                        <path
                                          d="M3 7H11"
                                          strokeWidth={2}
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          className="opacity-0 group-has-indeterminate:opacity-100"
                                        />
                                      </svg>
                                    </div>
                                    <label
                                      htmlFor={'PTR-API'}
                                      className={classNames(
                                        'text-gray-800',
                                        'ml-3 block text-lg mcfont font-medium leading-6 ',
                                      )}>
                                      APIs
                                    </label>
                                  </div>
                                </div>
                              </fieldset>

                              {errors.ptr2 && (
                                <p className="mt-2 mcfont text-md text-red-600">
                                  Required
                                </p>
                              )}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}

                  {/* {page === 2 && ptrSelection === "PTR_ALLOWED" && (
                    <div key={"ptrTypeSection"} className="col-span-full mt-10">
                      <label className="block text-xl mcfont font-semibold mt-10 mb-4 text-gray-900">
                        Should this content be available through partner
                        co-branded sites, partner APIs, or both?
                      </label>

                      <fieldset className="mt-6 flex flex-col">
                        <div className="space-y-4 mb-2 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
                          <div
                            key={"PTR-Cobrand"}
                            className="flex items-center"
                          >
                            <input
                              {...register("ptr2", {
                                required: true,
                              })}
                              type="radio"
                              id={"PTR-Cobrand"}
                              value={"PTR-Cobrand"}
                              className="h-4 w-4 border-gray-300 text-orange-400 focus:ring-orange-400"
                            />

                            <label
                              htmlFor={"PTR-Cobrand"}
                              className={classNames(
                                "text-gray-800",
                                "ml-3 block text-lg mcfont font-medium leading-6 "
                              )}
                            >
                              Co-branded sites
                            </label>
                          </div>
                          <div key={"PTR-API"} className="flex items-center">
                            <input
                              {...register("ptr2", {
                                required: true,
                              })}
                              type="radio"
                              id={"PTR-API"}
                              value={"PTR-API"}
                              className="h-4 w-4 border-gray-300 text-orange-400 focus:ring-orange-400"
                            />
                            <label
                              htmlFor={"PTR-API"}
                              className={classNames(
                                "text-gray-800",
                                "ml-3 block text-lg mcfont font-medium leading-6 "
                              )}
                            >
                              APIs
                            </label>
                          </div>

                          <div key={"PTR-Both"} className="flex items-center">
                            <input
                              {...register("ptr2", {
                                required: true,
                              })}
                              type="radio"
                              id={"PTR-Both"}
                              value={"PTR-Both"}
                              className="h-4 w-4 border-gray-300 text-orange-400 focus:ring-orange-400"
                            />

                            <label
                              htmlFor={"PTR-Both"}
                              className={classNames(
                                "text-gray-800",
                                "ml-3 block text-lg mcfont font-medium leading-6 "
                              )}
                            >
                              Both types of B2B2C integrations
                            </label>
                          </div>
                        </div>
                      </fieldset>
                      {errors.ptr2 && (
                        <p className="mt-2 mcfont text-md text-red-600">
                          Required
                        </p>
                      )}
                    </div>
                  )} */}

                  {page === 3 && (
                    <motion.div
                      key="step4"
                      className="col-span-full"
                      initial={{ opacity: 0, x: '100vw' }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={transition}>
                      <label className="block text-xl mcfont font-semibold text-gray-900">
                        What type of content are you looking for?
                      </label>

                      <button
                        type="button"
                        onClick={selectAllContentTypes}
                        className="mcfont pt-2 text-md underline font-semibold text-[#CF4500] hover:text-orange-800">
                        Select all
                      </button>

                      <div className="mt-4">
                        {contentTypes.map(option => (
                          <div
                            key={option.value}
                            className="relative flex items-center mb-1">
                            <div className="flex items-center">
                              <div className="group grid size-4 grid-cols-1">
                                <input
                                  {...register('contentType', {
                                    required: true,
                                  })}
                                  id={option.value}
                                  value={option.value}
                                  type="checkbox"
                                  className="col-start-1 row-start-1 appearance-none rounded-sm border border-gray-300 bg-white checked:border-orange-400 checked:bg-orange-400 indeterminate:border-orange-400 indeterminate:bg-orange-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-400 disabled:border-gray-300 disabled:bg-gray-100 disabled:checked:bg-gray-100 forced-colors:appearance-auto"
                                />
                                <svg
                                  fill="none"
                                  viewBox="0 0 14 14"
                                  className="pointer-events-none col-start-1 row-start-1 size-3.5 self-center justify-self-center stroke-white group-has-disabled:stroke-gray-950/25">
                                  <path
                                    d="M3 8L6 11L11 3.5"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="opacity-0 group-has-checked:opacity-100"
                                  />
                                  <path
                                    d="M3 7H11"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="opacity-0 group-has-indeterminate:opacity-100"
                                  />
                                </svg>
                              </div>
                            </div>
                            <div className="ml-3">
                              <label
                                htmlFor={option.value}
                                className="font-medium text-lg mcfont text-gray-900">
                                {option.label}
                              </label>
                            </div>
                          </div>
                        ))}

                        {errors.contentType && (
                          <p
                            className="mt-2 text-md mcfont text-red-600"
                            id="email-error">
                            Required
                          </p>
                        )}
                      </div>

                      {/* TODO: New stuff here */}
                      {Object.keys(contentType).length !== 0 && (
                        <div>
                          <label className="block mt-8 text-xl mcfont font-semibold mb-2 text-gray-900">
                            Are you looking for any of the following special
                            product types?
                          </label>

                          <p className="text-md mcfont text-gray-500">
                            Optional. Please select all that apply.
                          </p>

                          <div className="mt-2">
                            {specialProductTypes.map(option => (
                              <div
                                key={option.value}
                                className="relative flex items-center mb-1">
                                <div className="flex items-center">
                                  <div className="group grid size-4 grid-cols-1">
                                    <input
                                      {...register('specialProductTypes', {
                                        required: false,
                                      })}
                                      id={option.value}
                                      value={option.value}
                                      type="checkbox"
                                      className="col-start-1 row-start-1 appearance-none rounded-sm border border-gray-300 bg-white checked:border-orange-400 checked:bg-orange-400 indeterminate:border-orange-400 indeterminate:bg-orange-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-400 disabled:border-gray-300 disabled:bg-gray-100 disabled:checked:bg-gray-100 forced-colors:appearance-auto"
                                    />
                                    <svg
                                      fill="none"
                                      viewBox="0 0 14 14"
                                      className="pointer-events-none col-start-1 row-start-1 size-3.5 self-center justify-self-center stroke-white group-has-disabled:stroke-gray-950/25">
                                      <path
                                        d="M3 8L6 11L11 3.5"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="opacity-0 group-has-checked:opacity-100"
                                      />
                                      <path
                                        d="M3 7H11"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="opacity-0 group-has-indeterminate:opacity-100"
                                      />
                                    </svg>
                                  </div>
                                </div>

                                <div className="ml-3">
                                  <label
                                    htmlFor={option.value}
                                    className="font-medium text-lg mcfont text-gray-900">
                                    {option.label}
                                  </label>
                                </div>

                                <Tooltip
                                  title={
                                    <div
                                      className="mcfont"
                                      style={{ textAlign: 'center' }}>
                                      {option.description}
                                    </div>
                                  }>
                                  <InformationCircleIcon
                                    className="ml-1.5 h-5 w-5 cursor-help text-stone-700"
                                    aria-hidden="true"
                                  />
                                </Tooltip>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </motion.div>
                  )}

                  {page === 4 && (
                    <motion.div
                      key="step3"
                      initial={{ opacity: 0, x: '100vw' }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={transition}>
                      <label className="block text-xl mcfont font-semibold text-gray-900">
                        Which passion categories are you interested in
                        searching?
                      </label>

                      <button
                        type="button"
                        onClick={selectAllCategories}
                        className="mcfont pt-3 text-md underline font-semibold text-[#CF4500] hover:text-orange-800">
                        Select all
                      </button>

                      <div className="mt-4">
                        {categories.map((option, index) => (
                          <div
                            key={option.value}
                            className="relative flex items-center mb-1">
                            <div className="flex items-center">
                              <div className="group grid size-4 grid-cols-1">
                                <input
                                  {...register('category', { required: true })}
                                  id={option.value}
                                  value={option.value}
                                  type="checkbox"
                                  className="col-start-1 row-start-1 appearance-none rounded-sm border border-gray-300 bg-white checked:border-orange-400 checked:bg-orange-400 indeterminate:border-orange-400 indeterminate:bg-orange-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-400 disabled:border-gray-300 disabled:bg-gray-100 disabled:checked:bg-gray-100 forced-colors:appearance-auto"
                                  onChange={
                                    option.value === 'Sports'
                                      ? handleSportsChange
                                      : undefined
                                  }
                                />
                                <svg
                                  fill="none"
                                  viewBox="0 0 14 14"
                                  className="pointer-events-none col-start-1 row-start-1 size-3.5 self-center justify-self-center stroke-white group-has-disabled:stroke-gray-950/25">
                                  <path
                                    d="M3 8L6 11L11 3.5"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="opacity-0 group-has-checked:opacity-100"
                                  />
                                  <path
                                    d="M3 7H11"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="opacity-0 group-has-indeterminate:opacity-100"
                                  />
                                </svg>
                              </div>
                            </div>
                            <div className="ml-3">
                              <label
                                htmlFor={option.value}
                                className="font-medium text-lg mcfont text-gray-900">
                                {option.label}
                              </label>
                            </div>
                          </div>
                        ))}

                        {errors.category && (
                          <p
                            className="mt-2 mcfont text-sm text-red-600"
                            id="email-error">
                            Category is required
                          </p>
                        )}
                      </div>

                      {isSportsChecked && (
                        <div className="col-span-full mt-10">
                          <label className="block text-xl mcfont font-semibold text-gray-800 mt-10 mb-3">
                            And, regarding sports, which ones would you like to
                            search?
                          </label>

                          <button
                            type="button"
                            onClick={selectAllSports}
                            className="mcfont pb-4 text-md underline font-semibold text-[#CF4500] hover:text-orange-800">
                            Select all
                          </button>

                          <Controller
                            name="sports"
                            rules={{ required: true }}
                            control={control}
                            render={({ field }) => (
                              <Select
                                {...field}
                                defaultValue={field.defaultValue}
                                mode="multiple"
                                allowClear
                                style={{ width: '100%' }}
                                size="large"
                                placeholder="Select all sports that apply"
                                onChange={values => field.onChange(values)}
                                options={sports}
                              />
                            )}
                          />

                          {errors.sports && (
                            <p className="mt-2 text-sm mcfont text-red-600">
                              Since you selected "Sports", please specify which
                              sports you'd like to include.
                            </p>
                          )}
                        </div>
                      )}
                    </motion.div>
                  )}

                  {page === 5 && (
                    <motion.div
                      key="step5"
                      className="col-span-full"
                      initial={{ opacity: 0, x: '100vw' }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={transition}>
                      <label className="block text-xl mcfont font-semibold text-gray-900">
                        In which languages would you like to search for content?
                      </label>

                      <p className="text-md mcfont mt-2 text-gray-500">
                        Keep in mind that a single piece of content might be
                        available in multiple languages.
                      </p>

                      <button
                        type="button"
                        onClick={selectAllLanguages}
                        className="mcfont pt-4 text-md underline font-semibold text-[#CF4500] hover:text-orange-800">
                        Select all
                      </button>

                      <div className="mt-4">
                        <Controller
                          name="languages"
                          rules={{ required: true }}
                          control={control}
                          render={({ field }) => (
                            <Select
                              {...field}
                              defaultValue={field.defaultValue}
                              mode="multiple"
                              allowClear
                              style={{ width: '100%' }}
                              size="large"
                              placeholder="Select all languages that apply"
                              onChange={values => field.onChange(values)}
                              options={languages}
                            />
                          )}
                        />
                        {errors.languages && (
                          <p
                            className="mt-2 text-md mcfont text-red-600"
                            id="languages-error">
                            Required
                          </p>
                        )}
                      </div>
                    </motion.div>
                  )}

                  <div className="pt-7 flex items-center gap-x-6">
                    {page !== 0 && (
                      <button
                        type="button"
                        className="font-semibold mcfont leading-6 text-gray-900"
                        onClick={() => setPage(page - 1)}>
                        Back
                      </button>
                    )}

                    {page === 5 ? (
                      <button
                        type="submit"
                        className="flex items-center rounded-md bg-orange-600 px-3 py-2 font-semibold text-white shadow-sm hover:bg-orange-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600">
                        <MagnifyingGlassIcon
                          className="-ml-0.5 h-5 w-5 mr-1"
                          aria-hidden="true"
                        />
                        Search
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="w-28 mcfont rounded-md bg-orange-600 px-3 py-2 font-semibold text-white shadow-sm hover:bg-orange-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600">
                        Next <span aria-hidden="true">→</span>
                      </button>
                    )}
                  </div>
                </AnimatePresence>
              </form>
            </div>
          </div>
        </motion.div>
      </main>
    </>
  );
}
