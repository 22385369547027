import { Switch } from '@headlessui/react';
import { pdf } from '@react-pdf/renderer';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import useDeepCompareEffect from 'use-deep-compare-effect';
import ProductPDFDocument from '../components/ProductPdf';
import { categories } from '../data/categories';
import ContentSummary from './Content/ContentSummary';

import { ChevronRightIcon } from '@heroicons/react/20/solid';

import { SearchOutlined } from '@ant-design/icons';
import {
  DocumentChartBarIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/outline';
import { ArrowDownTrayIcon, ChevronDownIcon } from '@heroicons/react/24/solid';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Input,
  Modal,
  Row,
  Select,
  Table,
  Tooltip,
  TreeSelect,
} from 'antd';
import dayjs from 'dayjs';
import { Helmet } from 'react-helmet';
import { contentTypesCondensed } from '../data/contentTypes';
import { redemptionTypes } from '../data/redemptionTypes';
import { sports } from '../data/sports';
import { subcategories } from '../data/subcategories';
import { supabase } from '../supabaseClient';
import {
  hasExportPrivileges,
  isAdmin,
  regionMapping,
  useUser,
} from '../UserProvider';
import { classNames, cleanString, convertToCSV } from '../utils';
import ProductInfoView from './Content/ProductInfoView';

import { debounce } from 'lodash';
import { specialContentTypeStrings } from './Content/SubmitContentPage';
import { PaginatedItems } from './PaginatedItems';

const { SHOW_PARENT } = TreeSelect;

const SearchResults = () => {
  const location = useLocation();

  const { user, languages, countriesByRegion, sponsorships } = useUser();

  const sponsorshipsWithSelectAll = [
    { label: 'Select All', value: 'all' },
    ...sponsorships,
  ];

  // Provided on navigation (location.state) from the CatalogSearch component:
  const specialProductTypes = Array.isArray(location.state.specialProductTypes)
    ? location.state.specialProductTypes
    : [];

  const emptyState = {
    contentStatus: [],
    availabilityDate: null,
    category: [],
    contentType: [],
    countries: [],
    globalAvailability: [],
    ix: [],
    ptr: false,
    ptr2: [],
    redemptionType: [],
    subcategories: [],
    priceGroup: false,
    ecoFriendly: false,
    isIconic: false,
    epic: false,
    privilegedAccess: false,
    specialTreat: false,
    languages: [],
    linkedSponsorships: [],
  };

  const {
    control,
    handleSubmit,
    register,
    watch,
    setValue,
    reset,
    unregister,
    formState: { isDirty, errors },
  } = useForm({
    mode: 'onChange', // or you can use "onBlur"
    defaultValues: location.state
      ? {
          contentStatus: location.state.contentStatus,
          countries: location.state.countries,
          globalAvailability: location.state.globalAvailability,
          ix: location.state.ix,
          ptr: location.state.ptr === 'PTR_ALLOWED',
          ptr2: location.state.ptr2,
          availabilityDate: location.state.availabilityDate
            ? dayjs(location.state.availabilityDate)
            : null,
          category: location.state.category,
          sports: location.state.sports ?? [],
          contentType: location.state.contentType,
          languages: location.state.languages,

          ecoFriendly: specialProductTypes.includes(
            specialContentTypeStrings.ecoFriendly,
          ),
          isIconic: specialProductTypes.includes(
            specialContentTypeStrings.iconic,
          ),
          epic: specialProductTypes.includes(specialContentTypeStrings.epic),
          privilegedAccess: specialProductTypes.includes(
            specialContentTypeStrings.privilegedAccess,
          ),
          specialTreat: specialProductTypes.includes(
            specialContentTypeStrings.specialTreat,
          ),
        }
      : {},
  });

  const [loading, setLoading] = useState(false);
  const [isProductInfoVisible, setProductInfoVisible] = useState(false);
  const [isSummaryModalVisible, setIsSummaryModalVisible] = useState(true);
  const [pdfLoading, setPdfLoading] = useState(false);

  const [data, setData] = useState([]);
  const [summary, setSummary] = useState(null);

  const [searchTerm, setSearchTerm] = useState('');
  const [tablePageSize, setTablePageSize] = useState(15);

  const [selectedRowData, setSelectedRowData] = useState(null);
  const category = watch('category', []);
  const isPtrSwitchOn = watch('ptr');

  const formValues = watch();

  const [showTable, setShowTable] = useState(false);
  const [sortKey, setSortKey] = useState('passionCategory'); // default sort

  useEffect(() => {
    if (!category.includes('Sports')) {
      unregister('sports'); // This will unregister and clear the value of the 'sports' field
    }
    if (!category.includes('Entertainment')) {
      unregister('exclude_live_nation');
    }
  }, [category, unregister]);

  const debouncedFetchData = debounce(() => {
    fetchData(formValues);
  }, 300);

  useDeepCompareEffect(() => {
    debouncedFetchData();

    return () => debouncedFetchData.cancel();
  }, [formValues]);

  const fetchData = async () => {
    console.log('fetchData was called');
    setLoading(true);

    const regionToCountries = Object.fromEntries(
      countriesByRegion.map(region => [
        region.value,
        region.children.map(country => country.value),
      ]),
    );

    // Replace any region names with all of the countries in that region.
    let countries = formValues.countries
      ? formValues.countries.flatMap(
          countryOrRegion =>
            regionToCountries[countryOrRegion] || countryOrRegion,
        )
      : [];

    let globalAvailability = formValues.globalAvailability;
    if (globalAvailability && globalAvailability.length === 1) {
      let val = globalAvailability[0];
      if (val === 'Available Globally') {
        globalAvailability = true;
      } else {
        globalAvailability = false;
      }
    } else {
      globalAvailability = undefined;
    }

    console.log('ix: ', formValues.ix);
    console.log('ptr1: ', formValues.ptr);
    console.log('ptr2: ', formValues.ptr2);

    console.log('exclude_live_nation value: ', formValues.exclude_live_nation);

    let shouldExcludeLiveNationContent =
      formValues.exclude_live_nation === 'EXCLUDE_LIVE_NATION';

    let payload = {
      category: formValues.category,
      sports: formValues.sports,
      contentStatus: formValues.contentStatus,
      contentType: formValues.contentType,
      countries: countries,
      globalAvailability: globalAvailability,
      availabilityDate: formValues.availabilityDate,
      priceGroup: formValues.priceGroup,
      redemptionType: formValues.redemptionType,
      subcategories: formValues.subcategories,
      linkedSponsorship: formValues.linkedSponsorship,
      eitherPtrRequired: formValues.ptr,
      passThroughRights: formValues.ptr2 || [],
      implementationType: formValues.ix,
      languages: formValues.languages,
      specialCategories: getSpecialCategories(),
      excludeLiveNation: shouldExcludeLiveNationContent,
    };

    // console.log("Payload: ", payload);
    const { data: responseData, error } = await supabase.functions.invoke(
      'products-search-v3',
      {
        body: payload,
        headers: { Authorization: `Bearer ${user.sessionToken}` },
      },
    );

    if (error) {
      console.error('Error fetching data:', error);
    } else if (responseData.error) {
      console.error('Error fetching data row:', responseData.error);
    } else {
      // console.log("Successfully fetched data:");
      // console.log(responseData.summary);
      setData(responseData.data);
      setSummary(responseData.summary);
    }
    setLoading(false);
  };

  const generateAndStorePDF = async () => {
    try {
      setPdfLoading(true);

      const doc = (
        <ProductPDFDocument
          groupedProducts={groupedDataBySortKey(undefined)}
          user={user}
          searchParams={listOfFiltersFromFormData()}
          summary={summary}
        />
      );

      const blob = await pdf(doc).toBlob();
      const url = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.style.display = 'none';
      link.href = url;
      link.download = `priceless_${dayjs().format('YYYY-MM-DDTHH:mm')}.pdf`;
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(link);
    } catch (e) {
      console.error('Error generating PDF:', e);

      let log = JSON.stringify({
        error: e,
      });

      supabase.functions.invoke('pipeline-log', {
        body: { event: 'PDF ERROR', body: log },
        headers: { Authorization: `Bearer ${user.sessionToken}` },
      });

      alert('Error generating product PDF. Please try again.');
    } finally {
      setPdfLoading(false);
    }
  };

  const sortFunctions = {
    passionCategory: (a, b) => {
      if (a.passionCategory === null) return 1;
      if (b.passionCategory === null) return -1;
      return a.passionCategory.localeCompare(b.passionCategory);
    },
    region: (a, b) => {
      if (a.region === null) return 1;
      if (b.region === null) return -1;
      return a.region.localeCompare(b.region);
    },
    contentType: (a, b) => {
      if (a.contentType === null) return 1;
      if (b.contentType === null) return -1;
      return a.contentType.localeCompare(b.contentType);
    },
    redemptionType: (a, b) => {
      if (a.redemptionType === null) return 1;
      if (b.redemptionType === null) return -1;
      return a.redemptionType.localeCompare(b.redemptionType);
    },
    'Price (low-to-high)': (a, b) => {
      const priceA = a.price != null ? a.price : -Infinity;
      const priceB = b.price != null ? b.price : -Infinity;
      return priceA - priceB;
    },
    'Price (high-to-low)': (a, b) => {
      const priceA = a.price != null ? a.price : -Infinity;
      const priceB = b.price != null ? b.price : -Infinity;
      return priceB - priceA;
    },
  };

  const flattenGroupedData = groupedData => {
    const result = [];

    Object.keys(groupedData).forEach(groupTitle => {
      if (groupTitle.length > 0) {
        result.push({ isGroupTitle: true, groupTitle });
      }
      groupedData[groupTitle].forEach(item => {
        result.push(item);
      });
    });

    return result;
  };

  const downloadCSV = () => {
    const csvData = convertToCSV(data);
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    const date = dayjs().format('YYYY-MM-DDTHH:mm');
    a.download = `priceless_content_${date}.csv`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const stringSorter = key => (a, b) => {
    const valueA = a[key];
    const valueB = b[key];

    if (!valueA && !valueB) return 0; // Both are null/undefined
    if (!valueA) return -1; // Only a is null/undefined
    if (!valueB) return 1; // Only b is null/undefined

    return String(valueA).localeCompare(String(valueB));
  };

  const handleTableChange = pagination => {
    setTablePageSize(pagination.pageSize);
  };

  const columns = [
    {
      title: 'id',
      dataIndex: 'id',
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: 'Title',
      dataIndex: 'title',
      sorter: stringSorter('title'),
      render: text => (text ? cleanString(text) : '-'),
    },
    {
      title: 'Merchant',
      dataIndex: 'merchant',
      sorter: stringSorter('merchant'),
      render: text => text || '-',
    },
    {
      title: 'Type',
      dataIndex: 'contentType',
      sorter: stringSorter('contentType'),
      render: text => text || '-',
      filters: [
        {
          text: 'In-Person Experience',
          value: 'In-Person Experience',
        },
        {
          text: 'Digital Experience',
          value: 'Digital Experience',
        },
        {
          text: 'Editorial Content',
          value: 'Editorial Content',
        },
        {
          text: 'Offer',
          value: 'Offer',
        },
        {
          text: 'Physical Product',
          value: 'Physical Product',
        },
      ],
      onFilter: (value, record) => {
        if (!record.contentType) {
          return false;
        }
        return record.contentType.localeCompare(value) === 0;
      },
    },
    {
      title: 'Category',
      dataIndex: 'passionCategory',
      sorter: stringSorter('passionCategory'),
      render: text => text || '-',
      filters: [
        {
          text: 'Arts & Culture',
          value: 'Arts & Culture',
        },
        {
          text: 'Culinary',
          value: 'Culinary',
        },
        {
          text: 'Entertainment',
          value: 'Entertainment',
        },
        {
          text: 'Fashion and Shopping',
          value: 'Fashion and Shopping',
        },
        {
          text: 'Sports',
          value: 'Sports',
        },
        {
          text: 'Travel',
          value: 'Travel',
        },
        {
          text: 'Health and Wellness',
          value: 'Health and Wellness',
        },
      ],
      onFilter: (value, record) => {
        if (!record.passionCategory) {
          return false;
        }
        return record.passionCategory.localeCompare(value) === 0;
      },
    },
    {
      title: 'Country',
      dataIndex: 'country',
      sorter: stringSorter('country'),
      render: text => text || '-',
    },
  ];

  const handleShowSummary = () => {
    setIsSummaryModalVisible(true);
  };

  const handleSummaryModalClose = () => {
    setIsSummaryModalVisible(false);
  };

  const handleShowDetails = record => {
    setSelectedRowData(record);
    setProductInfoVisible(true);
  };

  const handleModalClose = () => {
    setProductInfoVisible(false);
  };

  const handleSearch = e => {
    setSearchTerm(e.target.value);
  };

  const filteredData = [...data]
    .sort(sortFunctions[sortKey])
    .filter(item =>
      Object.values(item).some(value =>
        String(value).toLowerCase().includes(searchTerm.toLowerCase()),
      ),
    );

  const getSpecialCategories = () => {
    const specialCategories = [
      formValues.isIconic ? specialContentTypeStrings.iconic : null,
      formValues.ecoFriendly ? specialContentTypeStrings.ecoFriendly : null,
      formValues.epic ? specialContentTypeStrings.epic : null,
      formValues.privilegedAccess
        ? specialContentTypeStrings.privilegedAccess
        : null,
      formValues.specialTreat ? specialContentTypeStrings.specialTreat : null,
    ].filter(Boolean);
    return specialCategories;
  };

  const listOfFiltersFromFormData = () => {
    let paramObject = formValues;
    paramObject = Object.fromEntries(
      Object.entries(paramObject).filter(([_, value]) => value !== undefined),
    );

    if (paramObject.ecoFriendly === true) {
      paramObject.ecoFriendly = 'Eco-friendly';
    } else {
      paramObject.ecoFriendly = '';
    }

    if (paramObject.isIconic === true) {
      paramObject.isIconic = 'Iconic';
    } else {
      paramObject.isIconic = '';
    }

    if (paramObject.contentStatus === false) {
      paramObject.contentStatus = '';
    }

    if (paramObject.priceGroup === false) {
      paramObject.priceGroup = '';
    }

    if (paramObject.ptr === false) {
      paramObject.ptr = '';
    }

    if (paramObject.ptr2 === false) {
      paramObject.ptr2 = '';
    }

    if (paramObject.globalAvailability === false) {
      paramObject.globalAvailability = '';
    }

    if (paramObject.epic === true) {
      paramObject.epic = 'Epic';
    } else {
      paramObject.epic = '';
    }

    if (paramObject.privilegedAccess === true) {
      paramObject.privilegedAccess = 'Privileged Access';
    } else {
      paramObject.privilegedAccess = '';
    }

    if (paramObject.specialTreat === true) {
      paramObject.specialTreat = 'Special Treat';
    } else {
      paramObject.specialTreat = '';
    }

    // Check if 'availabilityDate' key exists in the object
    if (paramObject.availabilityDate) {
      paramObject.availabilityDate = dayjs(paramObject.availabilityDate).format(
        'YYYY-MM-DD',
      );
    }

    return paramObject;
  };

  const groupedData = [
    'passionCategory',
    'region',
    'contentType',
    'redemptionType',
  ].includes(sortKey)
    ? filteredData.reduce((groups, item) => {
        const group =
          sortKey === 'region' && item[sortKey]
            ? regionMapping[item[sortKey]]
            : item[sortKey] || 'Other';

        if (!groups[group]) {
          groups[group] = [];
        }
        groups[group].push(item);
        return groups;
      }, {})
    : { '': filteredData };

  function groupedDataBySortKey(n) {
    // Consider only the first n elements of filteredData
    const slicedData = filteredData.slice(0, n);

    if (
      ['passionCategory', 'region', 'contentType', 'redemptionType'].includes(
        sortKey,
      )
    ) {
      return slicedData.reduce((groups, item) => {
        const group =
          sortKey === 'region' && item[sortKey]
            ? regionMapping[item[sortKey]]
            : item[sortKey] || 'Other';

        if (!groups[group]) {
          groups[group] = [];
        }
        groups[group].push(item);
        return groups;
      }, {});
    } else {
      // Price sort, will be flat (only one group)
      return { [`Sorted by: ${sortKey}`]: slicedData };
    }
  }

  const resetFilters = () => {
    setSearchTerm('');
    setSearchTerm('');
    reset(emptyState);
  };

  // console.log("groupedData is", groupedData);
  // console.log("flattenGroupedData is", flattenGroupedData(groupedData));

  return (
    <>
      <Helmet>
        <body className="bg-gray-100" />
        <title>Results | Priceless Content Catalog</title>
      </Helmet>

      <header className="bg-white shadow border-gray-200 ">
        <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8 flex justify-between items-center">
          <div>
            <h1 className="text-3xl font-bold tracking-tight mcfont text-gray-900 flex align-baseline">
              <span>Content Catalog</span>
              <ChevronRightIcon
                className="h-10 w-10 flex-shrink-0 text-gray-400"
                aria-hidden="true"
              />
              <span>Search Results</span>
            </h1>
            <p className="text-sm text-orange-700 font-semibold mcfont pt-3">
              Catalog last updated: {dayjs().format('YYYY-MM-DD')}
            </p>
          </div>

          <button
            onClick={handleShowSummary}
            className="inline-flex items-center text-sm font-semibold mcfont rounded-md bg-orange-600 px-3 py-2 text-white shadow-md hover:bg-orange-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600">
            View Search Summary
            <ChevronDownIcon className="h-6 w-6 pl-1 " aria-hidden="true" />
          </button>
        </div>
      </header>

      <main>
        <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center mb-4">
            <p className="flex items-center text-md text-orange-700 font-semibold mcfont">
              {filteredData.length.toLocaleString()} results found
              <Tooltip
                placement="bottom"
                title={
                  <div style={{ textAlign: 'center' }}>
                    Results show only Priceless Content, excluding MTR, MTLS,
                    and Benefits.
                  </div>
                }>
                <InformationCircleIcon
                  className="ml-1 mr-1 h-6 w-6 "
                  aria-hidden="true"
                />
              </Tooltip>
            </p>

            <div className="flex items-center space-x-4">
              {data.length > 0 && !showTable && (
                <div className="flex items-center mcfont">
                  <label
                    htmlFor="sort-select"
                    className="mr-2 text-sm font-semibold text-gray-700">
                    Sort by:
                  </label>
                  <div className="grid grid-cols-1">
                    <select
                      id="sort-select"
                      className="col-start-1 w-full row-start-1 font-semibold appearance-none rounded-md bg-white py-1.5 pr-8 pl-3 text-base text-gray-900 outline-1 -outline-offset-1 outline-gray-300 focus:outline-2 focus:-outline-offset-2 focus:outline-orange-400 sm:text-sm/6"
                      onChange={e => setSortKey(e.target.value)}
                      value={sortKey}>
                      <option value="passionCategory">Passion Category</option>
                      <option value="region">Region</option>
                      <option value="contentType">Content Type</option>
                      <option value="redemptionType">Redemption Type</option>
                      <option value="Price (low-to-high)">
                        Price (low-to-high)
                      </option>
                      <option value="Price (high-to-low)">
                        Price (high-to-low)
                      </option>{' '}
                    </select>
                    <ChevronDownIcon
                      aria-hidden="true"
                      className="pointer-events-none col-start-1 row-start-1 mr-2 size-5 self-center justify-self-end text-gray-500 sm:size-4"
                    />
                  </div>
                </div>
              )}

              {/* Only display export PDF if user has export rights */}
              {(isAdmin(user) || hasExportPrivileges(user)) &&
                data.length > 0 && (
                  <button
                    type="button"
                    onClick={generateAndStorePDF}
                    disabled={pdfLoading}
                    className={`inline-flex mcfont items-center gap-x-1.5 rounded-md px-3 py-2 text-sm font-semibold shadow-sm hover:bg-orange-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${
                      pdfLoading
                        ? 'bg-orange-50 text-orange-600'
                        : 'bg-orange-50 text-orange-600'
                    }`}>
                    <DocumentChartBarIcon
                      className="-ml-0.5 h-5 w-5"
                      aria-hidden="true"
                    />
                    {pdfLoading ? 'Generating PDF...' : 'Export as PDF'}
                  </button>
                )}

              {/* Only display download CSV if user is an admin */}
              {isAdmin(user) && data.length > 0 && (
                <button
                  type="button"
                  onClick={downloadCSV}
                  className="inline-flex mcfont items-center gap-x-1.5 rounded-md bg-orange-50 px-3 py-2 text-sm font-semibold text-orange-600 shadow-sm hover:bg-orange-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                  <ArrowDownTrayIcon
                    className="-ml-0.5 h-5 w-5"
                    aria-hidden="true"
                  />
                  Download as CSV
                </button>
              )}
            </div>
          </div>

          <Row gutter={[16, 16]}>
            <Col xl={6} lg={24} xs={24}>
              <Card
                extra={(() => {
                  if (formValues !== emptyState) {
                    return (
                      <button
                        type="button"
                        onClick={resetFilters}
                        className="mcfont text-sm underline font-semibold text-[#CF4500] hover:text-orange-800">
                        Reset
                      </button>
                    );
                  }
                  return null; // Return null or any default value when the condition is not met
                })()}
                title="Refine Your Search"
                className="mcfont">
                <label
                  htmlFor="about"
                  className="mb-3 block text-md font-semibold leading-6 text-gray-900">
                  Filter by Text
                </label>
                <Input
                  placeholder="Title, description, merchant"
                  onChange={handleSearch}
                  allowClear
                  value={searchTerm}
                  prefix={<SearchOutlined />}
                />

                <form className="pt-5 space-y-4 max-w-lg">
                  {/* // onSubmit={handleSubmit(onSubmit)} */}
                  <div className="col-span-full">
                    <label
                      htmlFor="about"
                      className="mb-2 block text-sm font-semibold leading-6 text-gray-900">
                      Availability
                    </label>

                    <fieldset className="mt-2">
                      <div className="space-y-4 mb-1 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
                        <div className="flex items-center">
                          <div className="group grid size-4 grid-cols-1">
                            <input
                              {...register('contentStatus')}
                              type="checkbox"
                              id={'Coming Soon'}
                              value={'Coming Soon'}
                              className="col-start-1 row-start-1 appearance-none rounded-sm border border-gray-300 bg-white checked:border-orange-400 checked:bg-orange-400 indeterminate:border-orange-400 indeterminate:bg-orange-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-400 disabled:border-gray-300 disabled:bg-gray-100 disabled:checked:bg-gray-100 forced-colors:appearance-auto"
                            />
                            <svg
                              fill="none"
                              viewBox="0 0 14 14"
                              className="pointer-events-none col-start-1 row-start-1 size-3.5 self-center justify-self-center stroke-white group-has-disabled:stroke-gray-950/25">
                              <path
                                d="M3 8L6 11L11 3.5"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="opacity-0 group-has-checked:opacity-100"
                              />
                              <path
                                d="M3 7H11"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="opacity-0 group-has-indeterminate:opacity-100"
                              />
                            </svg>
                          </div>
                          <label
                            htmlFor={'Coming Soon'}
                            className={classNames(
                              'text-gray-800',
                              'ml-3 block text-md font-medium leading-6 ',
                            )}>
                            Coming Soon
                          </label>
                        </div>

                        <div className="flex items-center">
                          <div className="group grid size-4 grid-cols-1">
                            <input
                              {...register('contentStatus')}
                              type="checkbox"
                              id={'Live'}
                              value={'Live'}
                              className="col-start-1 row-start-1 appearance-none rounded-sm border border-gray-300 bg-white checked:border-orange-400 checked:bg-orange-400 indeterminate:border-orange-400 indeterminate:bg-orange-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-400 disabled:border-gray-300 disabled:bg-gray-100 disabled:checked:bg-gray-100 forced-colors:appearance-auto"
                            />
                            <svg
                              fill="none"
                              viewBox="0 0 14 14"
                              className="pointer-events-none col-start-1 row-start-1 size-3.5 self-center justify-self-center stroke-white group-has-disabled:stroke-gray-950/25">
                              <path
                                d="M3 8L6 11L11 3.5"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="opacity-0 group-has-checked:opacity-100"
                              />
                              <path
                                d="M3 7H11"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="opacity-0 group-has-indeterminate:opacity-100"
                              />
                            </svg>
                          </div>
                          <label
                            htmlFor={'Live'}
                            className={classNames(
                              'text-gray-800',
                              'ml-3 block text-md font-medium leading-6 ',
                            )}>
                            Live
                          </label>
                        </div>
                      </div>
                    </fieldset>
                  </div>

                  <div className="col-span-full">
                    <label
                      htmlFor="about"
                      className="mb-2 block text-sm font-semibold leading-6 text-gray-900">
                      Availability Date
                    </label>

                    <Controller
                      name="availabilityDate"
                      control={control}
                      render={({ field }) => (
                        <DatePicker
                          {...field}
                          defaultValue={null}
                          className="w-full"
                          value={field.value}
                          onChange={values => field.onChange(values)}
                        />
                      )}
                    />
                  </div>

                  <div className="col-span-full">
                    <label
                      htmlFor="about"
                      className="mb-2 block text-sm font-semibold leading-6 text-gray-900">
                      Passion Category
                    </label>

                    <div className="mt-1">
                      <Controller
                        name="category"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            defaultValue={field.defaultValue}
                            mode="multiple"
                            allowClear
                            style={{ width: '100%' }}
                            // size="middle"
                            placeholder="Select all that apply"
                            onChange={values => field.onChange(values)}
                            options={categories}
                          />
                        )}
                      />
                    </div>

                    {category.includes('Entertainment') && (
                      <fieldset className="mt-3">
                        <div className="space-y-4 sm:flex sm:items-center sm:space-x-7 sm:space-y-0">
                          <div className="flex items-center">
                            <div className="group grid size-4 grid-cols-1">
                              <input
                                {...register('exclude_live_nation')}
                                type="checkbox"
                                id={'EXCLUDE_LIVE_NATION'}
                                value={'EXCLUDE_LIVE_NATION'}
                                className="col-start-1 row-start-1 appearance-none rounded-sm border border-gray-300 bg-white checked:border-orange-400 checked:bg-orange-400 indeterminate:border-orange-400 indeterminate:bg-orange-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-400 disabled:border-gray-300 disabled:bg-gray-100 disabled:checked:bg-gray-100 forced-colors:appearance-auto"
                              />
                              <svg
                                fill="none"
                                viewBox="0 0 14 14"
                                className="pointer-events-none col-start-1 row-start-1 size-3.5 self-center justify-self-center stroke-white group-has-disabled:stroke-gray-950/25">
                                <path
                                  d="M3 8L6 11L11 3.5"
                                  strokeWidth={2}
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="opacity-0 group-has-checked:opacity-100"
                                />
                                <path
                                  d="M3 7H11"
                                  strokeWidth={2}
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="opacity-0 group-has-indeterminate:opacity-100"
                                />
                              </svg>
                            </div>
                            <label
                              htmlFor={'EXCLUDE_LIVE_NATION'}
                              className={classNames(
                                'text-gray-800',
                                'ml-3 block text-md font-medium leading-6 ',
                              )}>
                              Exclude Live Nation® content
                            </label>
                          </div>
                        </div>
                      </fieldset>
                    )}
                  </div>

                  {category.includes('Sports') && (
                    <div className="col-span-full">
                      <label
                        htmlFor="about"
                        className="mb-2 block text-sm font-semibold leading-6 text-gray-900">
                        Sports
                      </label>

                      <div className="mt-1">
                        <Controller
                          name="sports"
                          control={control}
                          render={({ field }) => (
                            <Select
                              {...field}
                              defaultValue={field.defaultValue}
                              mode="multiple"
                              allowClear
                              style={{ width: '100%' }}
                              // size="middle"
                              placeholder="Select all that apply"
                              onChange={values => field.onChange(values)}
                              options={sports}
                            />
                          )}
                        />
                      </div>
                    </div>
                  )}

                  <div className="col-span-full">
                    <label
                      htmlFor="about"
                      className="mb-2 block text-sm font-semibold leading-6 text-gray-900">
                      Content Type
                    </label>

                    <div className="mt-1">
                      <Controller
                        name="contentType"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            defaultValue={field.defaultValue}
                            mode="multiple"
                            allowClear
                            style={{ width: '100%' }}
                            // size="middle"
                            placeholder="Select all that apply"
                            onChange={values => field.onChange(values)}
                            options={contentTypesCondensed}
                          />
                        )}
                      />
                    </div>
                  </div>

                  <div className="col-span-full">
                    <label
                      htmlFor="about"
                      className="mb-2 block text-sm font-semibold leading-6 text-gray-900">
                      Regions / Markets
                    </label>

                    <div className="">
                      <Controller
                        name="countries"
                        control={control}
                        render={({ field }) => (
                          <TreeSelect
                            {...field}
                            defaultValue={field.defaultValue}
                            treeData={countriesByRegion}
                            allowClear
                            treeCheckable={true}
                            showCheckedStrategy={SHOW_PARENT}
                            style={{ width: '100%' }}
                            placeholder="Select markets..."
                            onChange={values => field.onChange(values)}
                          />
                        )}
                      />
                    </div>
                  </div>

                  <div className="col-span-full">
                    <label
                      htmlFor="about"
                      className="mb-2 block text-sm font-semibold leading-6 text-gray-900">
                      Geographic Availability
                    </label>

                    <fieldset className="mt-2">
                      <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
                        <div
                          key={'Available Globally'}
                          className="flex items-center">
                          <div className="group grid size-4 grid-cols-1">
                            <input
                              {...register('globalAvailability')}
                              type="checkbox"
                              id={'Available Globally'}
                              value={'Available Globally'}
                              className="col-start-1 row-start-1 appearance-none rounded-sm border border-gray-300 bg-white checked:border-orange-400 checked:bg-orange-400 indeterminate:border-orange-400 indeterminate:bg-orange-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-400 disabled:border-gray-300 disabled:bg-gray-100 disabled:checked:bg-gray-100 forced-colors:appearance-auto"
                            />
                            <svg
                              fill="none"
                              viewBox="0 0 14 14"
                              className="pointer-events-none col-start-1 row-start-1 size-3.5 self-center justify-self-center stroke-white group-has-disabled:stroke-gray-950/25">
                              <path
                                d="M3 8L6 11L11 3.5"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="opacity-0 group-has-checked:opacity-100"
                              />
                              <path
                                d="M3 7H11"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="opacity-0 group-has-indeterminate:opacity-100"
                              />
                            </svg>
                          </div>
                          <label
                            htmlFor={'Available Globally'}
                            className={classNames(
                              'text-gray-700',
                              'ml-3 block text-md font-medium leading-6 ',
                            )}>
                            Global
                          </label>
                        </div>

                        <div key={'Local Only'} className="flex items-center">
                          <div className="group grid size-4 grid-cols-1">
                            <input
                              {...register('globalAvailability')}
                              type="checkbox"
                              id={'Local Only'}
                              value={'Local'}
                              className="col-start-1 row-start-1 appearance-none rounded-sm border border-gray-300 bg-white checked:border-orange-400 checked:bg-orange-400 indeterminate:border-orange-400 indeterminate:bg-orange-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-400 disabled:border-gray-300 disabled:bg-gray-100 disabled:checked:bg-gray-100 forced-colors:appearance-auto"
                            />
                            <svg
                              fill="none"
                              viewBox="0 0 14 14"
                              className="pointer-events-none col-start-1 row-start-1 size-3.5 self-center justify-self-center stroke-white group-has-disabled:stroke-gray-950/25">
                              <path
                                d="M3 8L6 11L11 3.5"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="opacity-0 group-has-checked:opacity-100"
                              />
                              <path
                                d="M3 7H11"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="opacity-0 group-has-indeterminate:opacity-100"
                              />
                            </svg>
                          </div>

                          <label
                            htmlFor={'Local Only'}
                            className={classNames(
                              'text-gray-700',
                              'ml-3 block text-md font-medium leading-6 ',
                            )}>
                            Local
                          </label>
                        </div>
                      </div>
                    </fieldset>
                  </div>

                  <div className="col-span-full">
                    <label
                      htmlFor="about"
                      className=" mt-4 block text-sm font-semibold leading-6 text-gray-900">
                      Pass-Through Rights
                    </label>

                    <fieldset className="mt-2">
                      <div className="space-y-4 sm:flex sm:items-center sm:space-x-7 sm:space-y-0">
                        <div className="flex items-center">
                          <div className="group grid size-4 grid-cols-1">
                            <input
                              {...register('ptr')}
                              type="checkbox"
                              id={'PTR_ALLOWED'}
                              value={'PTR_ALLOWED'}
                              className="col-start-1 row-start-1 appearance-none rounded-sm border border-gray-300 bg-white checked:border-orange-400 checked:bg-orange-400 indeterminate:border-orange-400 indeterminate:bg-orange-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-400 disabled:border-gray-300 disabled:bg-gray-100 disabled:checked:bg-gray-100 forced-colors:appearance-auto"
                            />
                            <svg
                              fill="none"
                              viewBox="0 0 14 14"
                              className="pointer-events-none col-start-1 row-start-1 size-3.5 self-center justify-self-center stroke-white group-has-disabled:stroke-gray-950/25">
                              <path
                                d="M3 8L6 11L11 3.5"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="opacity-0 group-has-checked:opacity-100"
                              />
                              <path
                                d="M3 7H11"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="opacity-0 group-has-indeterminate:opacity-100"
                              />
                            </svg>
                          </div>
                          <label
                            htmlFor={'PTR_ALLOWED'}
                            className={classNames(
                              'text-gray-800',
                              'ml-3 block text-md font-medium leading-6 ',
                            )}>
                            Has Pass-Through Rights
                          </label>
                        </div>
                      </div>
                    </fieldset>

                    {isPtrSwitchOn && (
                      <fieldset className="">
                        <Divider plain style={{ margin: '10px 8px' }} />

                        <div className="space-y-4 mt-3 mb-1 sm:flex sm:items-center sm:space-x-7 sm:space-y-0">
                          <div className="flex items-center">
                            <div className="group grid size-4 grid-cols-1">
                              <input
                                {...register('ptr2')}
                                type="checkbox"
                                id={'PTR-API'}
                                value={'PTR-API'}
                                className="col-start-1 row-start-1 appearance-none rounded-sm border border-gray-300 bg-white checked:border-orange-400 checked:bg-orange-400 indeterminate:border-orange-400 indeterminate:bg-orange-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-400 disabled:border-gray-300 disabled:bg-gray-100 disabled:checked:bg-gray-100 forced-colors:appearance-auto"
                              />
                              <svg
                                fill="none"
                                viewBox="0 0 14 14"
                                className="pointer-events-none col-start-1 row-start-1 size-3.5 self-center justify-self-center stroke-white group-has-disabled:stroke-gray-950/25">
                                <path
                                  d="M3 8L6 11L11 3.5"
                                  strokeWidth={2}
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="opacity-0 group-has-checked:opacity-100"
                                />
                                <path
                                  d="M3 7H11"
                                  strokeWidth={2}
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="opacity-0 group-has-indeterminate:opacity-100"
                                />
                              </svg>
                            </div>
                            <label
                              htmlFor={'PTR-API'}
                              className={classNames(
                                'text-gray-800',
                                'ml-3 block text-md font-medium leading-6 ',
                              )}>
                              API
                            </label>
                          </div>

                          <div className="flex items-center">
                            <div className="group grid size-4 grid-cols-1">
                              <input
                                {...register('ptr2')}
                                type="checkbox"
                                id={'PTR-Cobrand'}
                                value={'PTR-Cobrand'}
                                className="col-start-1 row-start-1 appearance-none rounded-sm border border-gray-300 bg-white checked:border-orange-400 checked:bg-orange-400 indeterminate:border-orange-400 indeterminate:bg-orange-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-400 disabled:border-gray-300 disabled:bg-gray-100 disabled:checked:bg-gray-100 forced-colors:appearance-auto"
                              />
                              <svg
                                fill="none"
                                viewBox="0 0 14 14"
                                className="pointer-events-none col-start-1 row-start-1 size-3.5 self-center justify-self-center stroke-white group-has-disabled:stroke-gray-950/25">
                                <path
                                  d="M3 8L6 11L11 3.5"
                                  strokeWidth={2}
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="opacity-0 group-has-checked:opacity-100"
                                />
                                <path
                                  d="M3 7H11"
                                  strokeWidth={2}
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="opacity-0 group-has-indeterminate:opacity-100"
                                />
                              </svg>
                            </div>

                            <label
                              htmlFor={'PTR-Cobrand'}
                              className={classNames(
                                'text-gray-800',
                                'ml-3 block text-md font-medium leading-6 ',
                              )}>
                              Co-brand sites
                            </label>
                          </div>
                        </div>
                      </fieldset>
                    )}
                  </div>

                  <div className="col-span-full">
                    <label
                      htmlFor="about"
                      className=" mt-4 block text-sm font-semibold leading-6 text-gray-900">
                      Implementation Type
                    </label>

                    <fieldset className="mt-2">
                      <div className="space-y-4 sm:flex sm:items-center sm:space-x-7 sm:space-y-0">
                        <div className="flex items-center">
                          <div className="group grid size-4 grid-cols-1">
                            <input
                              {...register('ix')}
                              type="checkbox"
                              id={'IX_B2B'}
                              value={'IX_B2B'}
                              className="col-start-1 row-start-1 appearance-none rounded-sm border border-gray-300 bg-white checked:border-orange-400 checked:bg-orange-400 indeterminate:border-orange-400 indeterminate:bg-orange-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-400 disabled:border-gray-300 disabled:bg-gray-100 disabled:checked:bg-gray-100 forced-colors:appearance-auto"
                            />
                            <svg
                              fill="none"
                              viewBox="0 0 14 14"
                              className="pointer-events-none col-start-1 row-start-1 size-3.5 self-center justify-self-center stroke-white group-has-disabled:stroke-gray-950/25">
                              <path
                                d="M3 8L6 11L11 3.5"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="opacity-0 group-has-checked:opacity-100"
                              />
                              <path
                                d="M3 7H11"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="opacity-0 group-has-indeterminate:opacity-100"
                              />
                            </svg>
                          </div>
                          <label
                            htmlFor={'IX_B2B'}
                            className={classNames(
                              'text-gray-800',
                              'ml-3 block text-md font-medium leading-6 ',
                            )}>
                            B2B
                          </label>

                          <Tooltip
                            placement="bottom"
                            title={
                              <div style={{ textAlign: 'center' }}>
                                B2B2C Mastercard owned: content sourced only for
                                B2B use
                              </div>
                            }>
                            <InformationCircleIcon
                              className="ml-1 mr-1 h-6 w-6 "
                              aria-hidden="true"
                            />
                          </Tooltip>
                        </div>

                        <div className="flex items-center">
                          <div className="group grid size-4 grid-cols-1">
                            <input
                              {...register('ix')}
                              type="checkbox"
                              id={'IX_B2C'}
                              value={'IX_B2C'}
                              className="col-start-1 row-start-1 appearance-none rounded-sm border border-gray-300 bg-white checked:border-orange-400 checked:bg-orange-400 indeterminate:border-orange-400 indeterminate:bg-orange-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-400 disabled:border-gray-300 disabled:bg-gray-100 disabled:checked:bg-gray-100 forced-colors:appearance-auto"
                            />
                            <svg
                              fill="none"
                              viewBox="0 0 14 14"
                              className="pointer-events-none col-start-1 row-start-1 size-3.5 self-center justify-self-center stroke-white group-has-disabled:stroke-gray-950/25">
                              <path
                                d="M3 8L6 11L11 3.5"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="opacity-0 group-has-checked:opacity-100"
                              />
                              <path
                                d="M3 7H11"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="opacity-0 group-has-indeterminate:opacity-100"
                              />
                            </svg>
                          </div>
                          <label
                            htmlFor={'IX_B2C'}
                            className={classNames(
                              'text-gray-800',
                              'ml-3 block text-md font-medium leading-6 ',
                            )}>
                            B2C
                          </label>
                        </div>
                      </div>
                    </fieldset>
                  </div>

                  <div className="col-span-full">
                    <label
                      htmlFor="about"
                      className="mb-2 block text-sm font-semibold leading-6 text-gray-900">
                      Redemption Type
                    </label>

                    <div className="">
                      <Controller
                        name="redemptionType"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            defaultValue={field.defaultValue}
                            mode="multiple"
                            allowClear
                            style={{ width: '100%' }}
                            placeholder="Select all that apply"
                            onChange={values => field.onChange(values)}
                            options={redemptionTypes}
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-span-full">
                    <label
                      htmlFor="about"
                      className="mb-2 block text-sm font-semibold leading-6 text-gray-900">
                      Subcategories
                    </label>

                    <div className="">
                      <Controller
                        name="subcategories"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            defaultValue={field.defaultValue}
                            mode="multiple"
                            allowClear
                            style={{ width: '100%' }}
                            placeholder="Select all that apply"
                            onChange={values => field.onChange(values)}
                            options={subcategories}
                          />
                        )}
                      />
                    </div>
                  </div>

                  <div className="col-span-full">
                    <label
                      htmlFor="about"
                      className="mb-2 block text-sm font-semibold leading-6 text-gray-900">
                      Price
                    </label>

                    <fieldset className="mt-2">
                      <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
                        <div className="flex items-center">
                          <div className="group grid size-4 grid-cols-1">
                            <input
                              {...register('priceGroup')}
                              type="checkbox"
                              id={'Price-Free'}
                              value={'Free'}
                              className="col-start-1 row-start-1 appearance-none rounded-sm border border-gray-300 bg-white checked:border-orange-400 checked:bg-orange-400 indeterminate:border-orange-400 indeterminate:bg-orange-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-400 disabled:border-gray-300 disabled:bg-gray-100 disabled:checked:bg-gray-100 forced-colors:appearance-auto"
                            />
                            <svg
                              fill="none"
                              viewBox="0 0 14 14"
                              className="pointer-events-none col-start-1 row-start-1 size-3.5 self-center justify-self-center stroke-white group-has-disabled:stroke-gray-950/25">
                              <path
                                d="M3 8L6 11L11 3.5"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="opacity-0 group-has-checked:opacity-100"
                              />
                              <path
                                d="M3 7H11"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="opacity-0 group-has-indeterminate:opacity-100"
                              />
                            </svg>
                          </div>
                          <label
                            htmlFor={'Price-Free'}
                            className={classNames(
                              'text-gray-800',
                              'ml-3 block text-md font-medium leading-6 ',
                            )}>
                            Free
                          </label>
                        </div>

                        <div className="flex items-center">
                          <div className="group grid size-4 grid-cols-1">
                            <input
                              {...register('priceGroup')}
                              type="checkbox"
                              id={'Price-Paid'}
                              value={'Paid'}
                              className="col-start-1 row-start-1 appearance-none rounded-sm border border-gray-300 bg-white checked:border-orange-400 checked:bg-orange-400 indeterminate:border-orange-400 indeterminate:bg-orange-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-400 disabled:border-gray-300 disabled:bg-gray-100 disabled:checked:bg-gray-100 forced-colors:appearance-auto"
                            />
                            <svg
                              fill="none"
                              viewBox="0 0 14 14"
                              className="pointer-events-none col-start-1 row-start-1 size-3.5 self-center justify-self-center stroke-white group-has-disabled:stroke-gray-950/25">
                              <path
                                d="M3 8L6 11L11 3.5"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="opacity-0 group-has-checked:opacity-100"
                              />
                              <path
                                d="M3 7H11"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="opacity-0 group-has-indeterminate:opacity-100"
                              />
                            </svg>
                          </div>

                          <label
                            htmlFor={'Price-Paid'}
                            className={classNames(
                              'text-gray-800',
                              'ml-3 block text-md font-medium leading-6 ',
                            )}>
                            Paid
                          </label>
                        </div>
                      </div>
                    </fieldset>
                  </div>

                  <div className="col-span-full">
                    <label
                      htmlFor="about"
                      className="mb-2 block text-sm font-semibold leading-6 text-gray-900">
                      Special Content Types
                    </label>

                    <fieldset className="mt-2">
                      <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
                        <div className="flex items-center">
                          <div className="group grid size-4 grid-cols-1">
                            <input
                              {...register('ecoFriendly')}
                              type="checkbox"
                              key={'ecoFriendly'}
                              id={'ecoFriendly'}
                              className="col-start-1 row-start-1 appearance-none rounded-sm border border-gray-300 bg-white checked:border-orange-400 checked:bg-orange-400 indeterminate:border-orange-400 indeterminate:bg-orange-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-400 disabled:border-gray-300 disabled:bg-gray-100 disabled:checked:bg-gray-100 forced-colors:appearance-auto"
                            />
                            <svg
                              fill="none"
                              viewBox="0 0 14 14"
                              className="pointer-events-none col-start-1 row-start-1 size-3.5 self-center justify-self-center stroke-white group-has-disabled:stroke-gray-950/25">
                              <path
                                d="M3 8L6 11L11 3.5"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="opacity-0 group-has-checked:opacity-100"
                              />
                              <path
                                d="M3 7H11"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="opacity-0 group-has-indeterminate:opacity-100"
                              />
                            </svg>
                          </div>
                          <label
                            htmlFor={'ecoFriendly'}
                            className="ml-3 block text-sm text-gray-900 font-medium leading-6">
                            Eco-friendly Experiences
                          </label>
                        </div>
                      </div>
                    </fieldset>

                    <fieldset className="mt-2">
                      <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
                        <div className="flex items-center">
                          <div className="group grid size-4 grid-cols-1">
                            <input
                              {...register('epic')}
                              type="checkbox"
                              key={'epic'}
                              id={'epic'}
                              className="col-start-1 row-start-1 appearance-none rounded-sm border border-gray-300 bg-white checked:border-orange-400 checked:bg-orange-400 indeterminate:border-orange-400 indeterminate:bg-orange-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-400 disabled:border-gray-300 disabled:bg-gray-100 disabled:checked:bg-gray-100 forced-colors:appearance-auto"
                            />
                            <svg
                              fill="none"
                              viewBox="0 0 14 14"
                              className="pointer-events-none col-start-1 row-start-1 size-3.5 self-center justify-self-center stroke-white group-has-disabled:stroke-gray-950/25">
                              <path
                                d="M3 8L6 11L11 3.5"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="opacity-0 group-has-checked:opacity-100"
                              />
                              <path
                                d="M3 7H11"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="opacity-0 group-has-indeterminate:opacity-100"
                              />
                            </svg>
                          </div>
                          <label
                            htmlFor={'epic'}
                            className="ml-3 block text-sm text-gray-900 font-medium leading-6">
                            Epic Experiences
                          </label>
                        </div>
                      </div>
                    </fieldset>

                    <fieldset className="mt-2">
                      <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
                        <div className="flex items-center">
                          <div className="group grid size-4 grid-cols-1">
                            <input
                              {...register('isIconic')}
                              type="checkbox"
                              key={'isIconic'}
                              id={'Iconic'}
                              className="col-start-1 row-start-1 appearance-none rounded-sm border border-gray-300 bg-white checked:border-orange-400 checked:bg-orange-400 indeterminate:border-orange-400 indeterminate:bg-orange-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-400 disabled:border-gray-300 disabled:bg-gray-100 disabled:checked:bg-gray-100 forced-colors:appearance-auto"
                            />
                            <svg
                              fill="none"
                              viewBox="0 0 14 14"
                              className="pointer-events-none col-start-1 row-start-1 size-3.5 self-center justify-self-center stroke-white group-has-disabled:stroke-gray-950/25">
                              <path
                                d="M3 8L6 11L11 3.5"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="opacity-0 group-has-checked:opacity-100"
                              />
                              <path
                                d="M3 7H11"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="opacity-0 group-has-indeterminate:opacity-100"
                              />
                            </svg>
                          </div>
                          <label
                            htmlFor={'Iconic'}
                            className="ml-3 block text-sm text-gray-900 font-medium leading-6">
                            Iconic Experiences
                          </label>
                        </div>
                      </div>
                    </fieldset>

                    <fieldset className="mt-2">
                      <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
                        <div className="flex items-center">
                          <div className="group grid size-4 grid-cols-1">
                            <input
                              {...register('privilegedAccess')}
                              type="checkbox"
                              key={'privilegedAccess'}
                              id={'privilegedAccess'}
                              className="col-start-1 row-start-1 appearance-none rounded-sm border border-gray-300 bg-white checked:border-orange-400 checked:bg-orange-400 indeterminate:border-orange-400 indeterminate:bg-orange-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-400 disabled:border-gray-300 disabled:bg-gray-100 disabled:checked:bg-gray-100 forced-colors:appearance-auto"
                            />
                            <svg
                              fill="none"
                              viewBox="0 0 14 14"
                              className="pointer-events-none col-start-1 row-start-1 size-3.5 self-center justify-self-center stroke-white group-has-disabled:stroke-gray-950/25">
                              <path
                                d="M3 8L6 11L11 3.5"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="opacity-0 group-has-checked:opacity-100"
                              />
                              <path
                                d="M3 7H11"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="opacity-0 group-has-indeterminate:opacity-100"
                              />
                            </svg>
                          </div>
                          <label
                            htmlFor={'privilegedAccess'}
                            className="ml-3 block text-sm text-gray-900 font-medium leading-6">
                            Privileged Access
                          </label>
                        </div>
                      </div>
                    </fieldset>

                    <fieldset className="mt-2">
                      <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
                        <div className="flex items-center">
                          <div className="group grid size-4 grid-cols-1">
                            <input
                              {...register('specialTreat')}
                              type="checkbox"
                              key={'specialTreat'}
                              id={'specialTreat'}
                              className="col-start-1 row-start-1 appearance-none rounded-sm border border-gray-300 bg-white checked:border-orange-400 checked:bg-orange-400 indeterminate:border-orange-400 indeterminate:bg-orange-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-400 disabled:border-gray-300 disabled:bg-gray-100 disabled:checked:bg-gray-100 forced-colors:appearance-auto"
                            />
                            <svg
                              fill="none"
                              viewBox="0 0 14 14"
                              className="pointer-events-none col-start-1 row-start-1 size-3.5 self-center justify-self-center stroke-white group-has-disabled:stroke-gray-950/25">
                              <path
                                d="M3 8L6 11L11 3.5"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="opacity-0 group-has-checked:opacity-100"
                              />
                              <path
                                d="M3 7H11"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="opacity-0 group-has-indeterminate:opacity-100"
                              />
                            </svg>
                          </div>
                          <label
                            htmlFor={'specialTreat'}
                            className="ml-3 block text-sm text-gray-900 font-medium leading-6">
                            Special Treat
                          </label>
                        </div>
                      </div>
                    </fieldset>
                  </div>

                  <div className="col-span-full">
                    <label
                      htmlFor="about"
                      className="mb-2 block text-sm font-semibold leading-6 text-gray-900">
                      Languages
                    </label>

                    <div className="">
                      <Controller
                        name="languages"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            defaultValue={field.defaultValue}
                            mode="multiple"
                            allowClear
                            style={{ width: '100%' }}
                            placeholder="Select all that apply"
                            onChange={values => field.onChange(values)}
                            options={languages}
                          />
                        )}
                      />
                    </div>
                  </div>

                  <div className="col-span-full">
                    <label
                      htmlFor="about"
                      className="mb-2 mt-2 block text-sm font-semibold leading-6 text-gray-900">
                      Linked Sponsorship
                    </label>

                    <div className="">
                      <Controller
                        name="linkedSponsorship"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            defaultValue={field.defaultValue}
                            mode="multiple"
                            allowClear
                            style={{ width: '100%' }}
                            placeholder="Select all that apply"
                            onChange={values => {
                              if (values.includes('all')) {
                                // Either select all or deselect all
                                const allValuesSelected = sponsorships.every(
                                  s => values.includes(s.value),
                                );
                                field.onChange(
                                  allValuesSelected
                                    ? []
                                    : sponsorships.map(s => s.value),
                                );
                              } else {
                                // Normal behavior
                                field.onChange(values);
                              }
                            }}
                            options={sponsorshipsWithSelectAll}
                          />
                        )}
                      />
                    </div>
                  </div>

                  {isAdmin(user) && (
                    <>
                      <Divider />
                      <div className="col-span-full">
                        <Switch.Group>
                          <div className="flex items-center justify-between mt-5">
                            <Switch.Label className="mr-4 mcfont font-bold text-sm text-gray-700 ">
                              Show results as table:
                            </Switch.Label>
                            <Switch
                              checked={showTable}
                              onChange={setShowTable}
                              className={`${
                                showTable ? 'bg-orange-600' : 'bg-gray-200'
                              } relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2`}>
                              <span
                                className={`${
                                  showTable ? 'translate-x-6' : 'translate-x-1'
                                } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
                              />
                            </Switch>
                          </div>
                        </Switch.Group>
                      </div>
                    </>
                  )}
                </form>
              </Card>
            </Col>
            <Col xl={18} xs={24}>
              {showTable ? (
                <Table
                  columns={columns}
                  size={'middle'}
                  loading={loading}
                  className="rounded-md shadow-md shadow-slate-200"
                  rowClassName={(record, index) =>
                    index % 2 === 0 ? 'table-row-light' : 'table-row-dark'
                  }
                  pagination={{
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} of ${total} items`,
                    pageSize: tablePageSize,
                  }}
                  onRow={record => {
                    return {
                      onClick: () => {
                        handleShowDetails(record);
                      },
                    };
                  }}
                  onChange={handleTableChange}
                  locale={{
                    emptyText: 'No results were found for your search.',
                  }}
                  dataSource={filteredData}
                  rowKey="id"
                />
              ) : loading ? (
                <div className="flex flex-col items-center mt-24">
                  <svg
                    className="w-20 h-20 text-orange-600 animate-spin"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24">
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  <div className="absolute inset-0 bg-transparent pointer-events-auto"></div>
                </div>
              ) : filteredData.length === 0 ? (
                <div className="flex flex-col items-center mt-24">
                  <p className="text-2xl mcfont font-semibold text-gray-500">
                    No results found
                  </p>
                  <p className="text-gray-400 mcfont">
                    Try adjusting your search filters
                  </p>
                </div>
              ) : (
                <PaginatedItems
                  data={flattenGroupedData(groupedData)}
                  itemsPerPage={18}
                  handleShowDetails={handleShowDetails}
                />
              )}
            </Col>
            <Modal
              title={
                selectedRowData && (
                  <div className="mcfont text-lg">
                    {cleanString(selectedRowData.title)}
                  </div>
                )
              }
              open={isProductInfoVisible}
              width={700}
              onCancel={handleModalClose}
              footer={[
                <Button key="back" onClick={handleModalClose}>
                  Return
                </Button>,
              ]}>
              {selectedRowData && (
                <div>
                  <ProductInfoView data={selectedRowData} />
                </div>
              )}
            </Modal>
            <Modal
              title={
                <div className="mcfont text-2xl font-semibold">
                  Your Search Summary
                </div>
              }
              open={isSummaryModalVisible}
              width="80%"
              onCancel={handleSummaryModalClose}
              footer={[
                <Button key="back" onClick={handleSummaryModalClose}>
                  Return
                </Button>,
              ]}>
              <ContentSummary
                summary={summary}
                currentSearchQuery={listOfFiltersFromFormData()}
              />
            </Modal>
          </Row>
        </div>
      </main>
    </>
  );
};

export default SearchResults;
